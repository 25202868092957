// src/GoldRock.js
import React from 'react';
import '../../styles/animation.scss'; // Import CSS file from src/styles

const GoldMining = () => {
  return (
    <> 
    <div className="Hammer hammer-animation" />     
    <div className="hit-effect-2" />    
    </>
  );
};

export default GoldMining;
