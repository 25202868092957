import * as React from 'react';
import { useNavigate, useLocation  } from "react-router-dom"; 
import { useTranslation } from 'react-i18next';

import assets from '../../assets.json';
 
export default function Footer(props) {
    const navigate = useNavigate();
    const { t }     = useTranslation();
    const location  = useLocation();
    const currentPath = location.pathname;
    const path = currentPath.substring(1);
 
    const goto = (path) => { 
        navigate('/' + path);
    }; 
    
    return (
        <div className="nav-footer" style={{ backgroundImage: `url('${assets.BOTTOM_BAR}')` }}> 
            <div onClick={() => goto("")} className={`nav-footer-items ${path === 'home' ? 'active' : ''} `}>
                <span className="footer-icon">
                    {path === 'home' || path === '' ? (
                        <span className="footer-icon-span">
                        <img src={assets.BTN_MAIN} height={60}/>
                        <span>{t('My Mine')}</span>
                        </span>
                    ) : (
                        <img src={assets.BTN_MAIN_UN}  height={50}/>
                    )}
                </span>
            </div> 
            <div onClick={() => goto("activity")} className={`nav-footer-items ${path === 'activity' ? 'active' : ''} `}>
                <span className="footer-icon">
                    {path === 'activity' ? (
                        <span className="footer-icon-span">
                        <img src={assets.BTN_ACTIVITY} height={60}/>
                        <span>{t('Activity')}</span>
                        </span>
                    ) : (
                        <img src={assets.BTN_ACTIVITY_UN}  height={50}/>
                    )} 
                </span>
            </div>
            <div onClick={() => goto("alliance")} className={`nav-footer-items ${path === 'alliance' ? 'active' : ''} `}>
                <span className="footer-icon">
                    {path === 'alliance' ? (
                        <span className="footer-icon-span">
                        <img src={assets.BTN_ALLIANCES} height={60}/>
                        <span>{t('Alliance')}</span>
                        </span>
                    ) : (
                        <img src={assets.BTN_ALLIANCES_UN}  height={50}/>
                    )}  
                </span>
            </div>
            <div onClick={() => goto("profile")}  className={`nav-footer-items ${path === 'profile' ? 'active' : ''} `}>
                <span className="footer-icon"> 
                    {path === 'profile' ? (
                        <span className="footer-icon-span">
                        <img src={assets.BTN_PROFILE} height={60}/>
                        <span>{t('Profile')}</span>
                        </span>
                    ) : (
                        <img src={assets.BTN_PROFILE_UN}  height={50}/>
                    )}  
                </span>
            </div> 
        </div> 
      );
} 