import { useNavigate } from 'react-router-dom';
import ModalService from './ModalService';
import { post } from '../../Services/api_helper'   


const clearLocalStorage = () => { 
  if (typeof window !== 'undefined') { 
      localStorage.clear();
  }
};


export const createApiCall = (endpoint, params = {}, callback) => async (navigate, t) => {
    const token = localStorage.getItem('token');
    if (token === null) {
      navigate('/login');
    } else {
      try {
        params.token = token; 
        const response = await post(endpoint, params);
        if (response.result !== 1) {
          if (response.message_code === "0008" || response.message_code === "0007") {
            ModalService.showModal(<div>{t("Invalid token.")}<br/>{t("Please Relogin The Game")}</div>, () => navigate("/login")); 
            clearLocalStorage(); 
          }  
          else if (response.message_code === "0002" ) {
            let formatErrors = null
            if (response.errors && response.errors.length > 0) { 
                formatErrors = response.errors.map(error => t(error.error)).join('\n');
            } 
            ModalService.showModal(<div>{formatErrors}</div>);
          }  
          else if(response.message_code === "401"){
            let transactionEndpoint = ["member/withdrawUsdt","wallet/topUpMiner","transaction/instantPay"]
       
            if(transactionEndpoint.includes(endpoint)){ 
              ModalService.showModal(<div>{t("Wrong transaction password")}</div>,{type: "error"});
            } else { 
              ModalService.showModal(<div>{t(response.message_code)}</div>);
            }
          }  
          else {
            ModalService.showModal(<div>{t(response.message_code)}</div>);
          }
        } else {
          callback(response); // Call the callback function with the response
        }
      } catch (error) {
        ModalService.showModal(<div>{JSON.stringify(error)}</div>);
      }
    }
};
export const authApiCall = (endpoint, params = {}, callback) => async (navigate, t) => {
    try { 
      const response = await post(endpoint, params);
      if (response.result !== 1) {
        if (response.message_code === "0008" || response.message_code === "0007") {
          localStorage.removeItem('token');
          ModalService.showModal(<div>{t(response.message_code)}</div>, () => navigate("/login"));
        } 
        else if (response.message_code === "0002" ) {
          let formatErrors = null
          if (response.errors && response.errors.length > 0) { 
              formatErrors = response.errors.map(error => t(error.error)).join('\n');
          } 
          ModalService.showModal(<div>{formatErrors}</div>);
        } 
        else {
          ModalService.showModal(<div>{t(response.message_code)}</div>);
        }
      } else {
        callback(response); // Call the callback function with the response
      }
    } catch (error) {
      ModalService.showModal(<div>{JSON.stringify(error)}</div>);
    } 
}; 