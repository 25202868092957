import axios from "axios"; 
import {encryptData, decryptData, genSign, logs, signature} from './Utils'  
import { Loading } from 'notiflix/build/notiflix-loading-aio';
import md5 from 'md5';

//apply base url for axios 
const API_URL = process.env.REACT_APP_API_URL; 
 
const baseUrl = parseDomain();

function parseDomain(){ 
  
    const domainMappings = [
      "https://c9yxgr1iz8vdkjpq.riddertitan.com",
      "https://8gwyp4xcnsj2013i.riddertitan.com",
      "https://s8hwe4l5aq2uxbfp.riddertitan.com",
      "https://6zlwj95a0oqbi2u1.riddertitan.com", 
    ];

    let myurl = null; 
    if(process.env.REACT_APP_ENV === "dev"){ 
      myurl = process.env.REACT_APP_API_URL; 
    }else{
      const randomIndex = Math.floor(Math.random() * domainMappings.length);
      myurl = domainMappings[randomIndex]+"/api";
    }
     
    return myurl; 
}

function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0,
          v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
}

const axiosApi = axios.create({
  baseURL: baseUrl,
});
 

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);
 

export async function post(url, data, config = {}) {
  const requestId = generateUUID();
  let postData = data;
  
  // Check if config has headers defined
  if (!config.headers) {
    config.headers = {};
  }  

  const i18nextLng = localStorage.getItem("lang")|| "en";    
  postData.lang = i18nextLng; 
  
  if(url !== "mobileLogin"){ 
    const member_id = parseInt(localStorage.getItem("member_id"));  
    const token = localStorage.getItem("token");  
    const timestamp = Math.round(Date.now()/1000) 
    postData.timestamp = timestamp 
    postData.signature = signature(member_id, token, timestamp)
    postData.member_id = member_id
  } 
  config.headers['Content-Type'] = 'application/json';
  config.headers['Api-Request-ID'] = requestId; 
 
  Loading.pulse({
    backgroundColor: 'rgba(0,0,0,0.6)',
  });

  const baseUrl = process.env.REACT_APP_API_URL;  
  url = baseUrl + "/" + url

  return axiosApi
    .post(url, postData, { ...config })
    .then((response) => handleResponse(response));
}

export async function getV2(url, data, config = {}) {
  let postData = data;
  let suffix = Math.floor(100000 + Math.random() * 900000);
  if(process.env.REACT_APP_ENV === 'dev')
  {
      suffix = 123456;
  }
     
  const i18nextLng = localStorage.getItem("i18nextLng");  
  config.headers['lang'] = i18nextLng || "en";
  config.headers['imei'] = suffix;
  config.headers['suffix'] = suffix;

  if (process.env.REACT_APP_LOCK === "true") {
    postData = {
      "post-data": encryptData(data, suffix)
    };
    config.headers['Content-Type'] = 'application/json'; // Update Content-Type for encrypted data
  }
  
  return axiosApi
    .get(url, postData, { ...config })
    .then((response) => handleResponse(response));
}

export async function postV2(url, data, config = {}) {
  let postData = data;
  
  // Check if config has headers defined
  if (!config.headers) {
    config.headers = {};
  } 
  let suffix = Math.floor(100000 + Math.random() * 900000);
  if(process.env.REACT_APP_ENV === 'dev')
  {
      suffix = 123456;
  }

  if (process.env.REACT_APP_LOCK === "true") {
    
    data.timestamp = Math.round(Date.now()/1000) 
    data.encode_sign = genSign(data); 

    postData = {
      "post-data": encryptData(data, suffix)
    };
  }
  if(data.token !== undefined)
  { 
    // axiosApi.defaults.headers.common["token"] = data.token;
  } 
  const i18nextLng = localStorage.getItem("i18nextLng");  
  config.headers['lang'] = i18nextLng || "en";
  config.headers['imei'] = suffix;
  config.headers['suffix'] = suffix;
  config.headers['Content-Type'] = 'application/json';
  // console.log("config")
  // console.log(config)
  
  Loading.pulse({
    backgroundColor: 'rgba(0,0,0,0.6)',
  });
  return axiosApi
    .post(url, postData, { ...config })
    .then((response) => handleResponse(response));
}

export async function put(url, data, config = {}) {
  let putData = data;
  if (process.env.REACT_APP_LOCK === "true") {
    let suffix = Math.floor(100000 + Math.random() * 900000);
    if(process.env.REACT_APP_ENV === 'dev')
    {
        suffix = 123456;
    }
    putData = {
      "post-data": encryptData(data, suffix)
    };
    config.headers['lang'] = "en";
    config.headers['imei'] = suffix;
    config.headers['suffix'] = suffix;
    config.headers['Content-Type'] = 'application/json';
  }

  return axiosApi
    .put(url, putData, { ...config })
    .then((response) => handleResponse(response));
}
 
export async function del(url, data, config = {}) {
  let postData = data;
  if (process.env.REACT_APP_LOCK === "true") {
    let suffix = Math.floor(100000 + Math.random() * 900000);
    if(process.env.REACT_APP_ENV === 'dev')
    {
        suffix = 123456;
    }
    postData = {
      "post-data": encryptData(data, suffix)
    };
    config.headers['lang'] = "en";
    config.headers['imei'] = suffix;
    config.headers['suffix'] = suffix;
    config.headers['Content-Type'] = 'application/json';
  }
  
  return axiosApi
    .delete(url, postData, { ...config })
    .then((response) => handleResponse(response));
} 

function handleResponse(response) {
  let responseData = response.data; 
  if (process.env.REACT_APP_LOCK === "true") {
    let decryptedData = decryptData(responseData.data, responseData.suffix);
    if (decryptedData !== null) {
      responseData = decryptedData; 
      responseData = JSON.parse(responseData); 
    } else {
      console.log("Handle decryption error")
    }
  }
  Loading.remove(1000);
  return responseData;
}