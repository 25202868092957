import React from 'react';
import { useTranslation } from 'react-i18next';  
import ModalBox from './ModalBox' 

import { 
    Button, 
    FormGroup, 
    Input, 
    InputGroupText,
    InputGroup, 
} from "reactstrap"; 

import assets from '../../assets.json';

const WalletModal = ({
  isOpen,
  handleClose,
  title, 
  walletAddress,
  disabled,
  onCopyClick,
  onWithdrawClick,
  children,
}) => {
  const { t } = useTranslation();

  const onSubmit = (event) => {
    event.preventDefault();
    // Add your custom logic here or leave it empty if not needed
  };

  return (
    <ModalBox
      isOpen={isOpen}
      handleClose={handleClose}
      title={t(title)}
    >
      <div className="transfer-form deposit-info">
        <form className="form" onSubmit={onSubmit} method="POST" autoComplete="off">
          <div className="transfer-content">
            <div className="text-center mt-4">
              <img className="bar-coin-icon" src={assets.USDT_BTN} height={50} />
            </div>

            <div className="form-group">
              <label>{t("Wallet address")}</label>
              <InputGroup className="input-group-alternative" style={{ backgroundImage: `url(${assets.TEXT_INPUT_BG})` }}>
                <Input value={walletAddress} disabled={disabled} />
                <span className="btn btn-request">
                  <button type="button" className="btnSubmit" onClick={onCopyClick}>
                    <img src={assets.SUBMIT_BTN} width={80} />
                    <span>{t("copy")}</span>
                  </button>
                </span>
              </InputGroup>
            </div>

            <div className="form-group">
              <div onClick={onWithdrawClick} className="btn-withdraw">
                <img src={assets.ICON_WITHDRAWAL} height={15} />{t("withdraw")}
              </div>
            </div>

            {children}
          </div>
        </form>
      </div>
    </ModalBox>
  );
};

export default WalletModal;