import React from 'react';  
import { useTranslation } from 'react-i18next';

import assets from '../../assets.json';

export default function BtnTransfer({ onClick }) { 
    const { t }     = useTranslation();
  
    return (
        <>
            <div className={`btn-transfer`} onClick={onClick} >
                <img src={assets.BTN_TRANSFER}  height={50} alt={t("transfer")}/>
                <span>
                    {t("Transfer")}
                </span>
            </div> 
        </>
      );
} 