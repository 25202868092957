
import React, {useEffect,useState } from 'react';

import { useNavigate } from 'react-router-dom'    
   
import { post } from '../../Services/api_helper'  
import { parseLog } from '../../Services/Utils'  
import ModalService from './ModalService';  
import { useTranslation } from 'react-i18next'; 
import { List } from 'react-vant';
import  { createApiCall }  from './createApiCall'

import Footer from './Footer';  

export default function Activity() {  
 
    const { t } = useTranslation();
    const navigate = useNavigate(); 
    const [list, setList] = useState([])
    const [finished, setFinished] = useState(false)
    const [ offset, setOffset ] = useState(0)
    const [ wallet_type, setWallet_type ] = useState("")
   
    useEffect(() => {     
        // eslint-disable-next-line
    }, []); 
  
    const activity =  async () => {
        const apiCall = createApiCall("activityLog/get", {
            offset: offset, 
            wallet_type: wallet_type
        }, (response) => { 
            let activity_log= response.activity_log 
            if(activity_log.length > 0){
                let totalLength = offset + activity_log.length
                setOffset(totalLength) 
                setList(v => [...v, ...activity_log])
            }
            if (activity_log.length == 0) {
              setFinished(true)
            }
        })
        await apiCall(navigate, t);
    };
      
    const categoryImages = {
        transaction: "assets/icon_activity_money_transaction.png",
        money_trans: "assets/icon_activity_Mining.png",
        plant: "assets/icon_activity_Plant__Transaction.png",
        reservation: "assets/icon_activity_reservation.png",
    };
    const handleSelectChange = (event) => {
        const value = event.target.value;
        setWallet_type(value);
        setOffset(0) 
        setList(() => []);   
      };


    return (
    <>
        <div className="activity">   
            <div className="list-wrapper"
            style={{ backgroundImage: "url('assets/border_activity.png')" }}> 
                <div className="list-title">
                    <h2>{t("Activity Log")}</h2>
                </div>
                <div className="list-filter"> 
                    <h4>{t("Filter Type")}</h4> 
                    <select value={wallet_type} onChange={handleSelectChange} className="filter-dropdown" 
                        style={{ backgroundImage: "url('assets/bar_password_keepin.png')" }}>
                        <option value="">{t("All")}</option>
                        <option value="usdt">{t("USDT")}</option>
                        <option value="miner">{t("Miner")}</option>
                        <option value="bonus">{t("Bonus")}</option>
                    </select>
                </div>
                <div className="list-content">  
                    <List finished={finished} onLoad={activity}>
                    {list.map((_, i) => (
                        <div className="list-row" key={i} title={i + 1}>
                            <span className="list-row-icon">
                                <img src={categoryImages[_.category]} width={20} />
                            </span>
                            <div className="list-row-content">
                                <div className="list-row-header">
                                    <span className="list-row-date">{_.created_at}</span>
                                </div>
                                <div className="list-row-desc">
                                    <div
                                        dangerouslySetInnerHTML={{__html: parseLog(_.message)}} />  
                                </div>
                            </div>
                        </div>
                    ))}
                    </List>
                </div>
            </div>

            <Footer/>
        </div> 
    </>
    );
};