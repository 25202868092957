import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next'; 
export default function AssetEmptySlot(props) {
     
    const { t }     = useTranslation();
    const EmptySlot = "assets/Empty-Slot.png";
    const AddBtn = "assets/add.png";
    
    return (
        <span className="SlotEmpty" onClick={props.onClick}>
            <img className="bg" src={EmptySlot} width={152}/>
            <img className="Addbtn" src={AddBtn} width={80}/>
            <span className="itemTitle">{t("Add reserve")}</span>
        </span>
    );
}
