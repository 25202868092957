import React from 'react';

import assets from '../../assets.json';

const SplashScreen = () => {
 
  return (
    <div className="splash-screen " style={{ backgroundImage: `url(${assets.BG_PROFILE})` }}>
        <div className="splash-wrapper ">
            <img src={assets.LOGO} width={180} />  
          <div className="container">
              <div className="loader"></div>
              <div className='bar'></div>
          </div>
        </div>
    </div>
  );
};

export default SplashScreen;