import { useState, useEffect } from 'react';

const useSplashScreen = (second=5) => {
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  useEffect(() => {
    const storedValue = localStorage.getItem('showSplashScreen');
    if (storedValue === null || storedValue === '0') {
        setShowSplashScreen(true);
        localStorage.setItem('showSplashScreen', '1');
        setTimeout(() => {
            setShowSplashScreen(false); 
        }, second*1000); // 5 seconds
    } else{
        setShowSplashScreen(false);
    }
  }, []);

  return showSplashScreen;
};

export default useSplashScreen;