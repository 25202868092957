import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next'; 
import Countdown from './Countdown';
import BtnMining from './BtnMining';
import assets from '../../assets.json';

export default function AssetMine(props) {
     
    const { t }     = useTranslation();
    const { onClick, detail, type } = props; 
    
    // reserved
    // buy
    // selling
    // sell
    // sell_received_instant_pay
    // watering
    // splitMatched

    // unpaid
    // cancelled
    // pending 

    const silverImages = [assets.SILVER1, assets.SILVER2, assets.SILVER3, assets.SILVER4, assets.SILVER5, assets.SILVER6, assets.SILVER7];

    return ( 
        <span 
            className="SlotMine SlotEmpty"
        >
            <img className="bg" src={assets.EMPTY_SLOT} width={152}/>
            <div className="SlotMineDetail">
                {type === "watering" && detail.status === "active" && ( 
                    <div>
                        <div className="mining_slot" onClick={(event) => props.onClick(detail)} >
                            <div className="bar_slot_info">
                                <img className="bar_slot_info_bg" src={assets.BAR_SLOT_INFO} width={100}/>
                                <span className="level_badge">
                                    <img src={assets.LEVEL_BASE} width={20} />
                                    <span>Lv<br/>{detail.level}</span>
                                </span>
                                <span>{t(detail.title)}</span>
                            </div>
                            <div className="mineType"> 
                                {detail.watering_count >= 0 && detail.watering_count < silverImages.length && (
                                    <img className="mineTypeBg" src={silverImages[detail.watering_count]} width={100}/>
                                )}
                            </div>
                        </div>
                        <div className="mineAction" onClick={(event) => props.onClick(detail)} >  
                            <BtnMining initialSeconds={detail.watering_count_down} className="mineCoundown" /> 
                        </div>
                    </div>
                )}
                {type === "reserved" && (detail.package_id === 1 || detail.package_id === 2) && ( 
                    <div>
                        <div className="mining_slot" onClick={(event) => props.onClick(detail)} >
                            <div className="bar_slot_info">
                                <img className="bar_slot_info_bg" src={assets.BAR_SLOT_INFO} width={100}/>
                                <span>{t("On wait for match")}</span>
                            </div>
                            <div className="mineType"> 
                                <img className="mineTypeBg" src={assets.SLIVER_TICKET} width={100}/>
                            </div>
                            <div className="mineAction">  
                                <Countdown initialSeconds={detail.count_down} className="mineCoundown" />
                            </div>
                        </div>
                    </div>
                )}
                {type === "reserved" && detail.package_id  === 3 && ( 
                    <div>
                        <div className="mining_slot" onClick={(event) => props.onClick(detail)} >
                            <div className="bar_slot_info">
                                <img className="bar_slot_info_bg" src={assets.BAR_SLOT_INFO} width={100}/>
                                <span>{t("On wait for match")}</span>
                            </div>
                            <div className="mineType">  
                                <img className="mineTypeBg" src={assets.TICKET_GOLD} width={100}/>
                            </div>
                            <div className="mineAction">  
                                <Countdown initialSeconds={detail.count_down} className="mineCoundown" />
                            </div>
                        </div>
                    </div>
                )}
                {type === "buy" && detail.status === "pair"  && ( 
                    <div>
                        <div className="mining_slot" onClick={(event) => props.onClick(detail)} >
                            <div className="bar_slot_info">
                                <img className="bar_slot_info_bg" src={assets.BAR_SLOT_INFO} width={100}/>
                                <span>{t("Matched!!")}</span>
                            </div>
                            <div className="mineType">   
                                <img className="mineTypeBg" src={assets.SILVERSTARTER} width={100}/>
                            </div>
                            <div className="mineAction">  
                                <Countdown initialSeconds={detail.count_down} className="mineCoundown" />
                                <div className="min-remark">{t("Please Pay ")} {detail.value} <img className="bar-coin-icon" src={`assets/coin_USDT.png`} height={22}/></div>
                            </div>
                        </div>
                    </div>
                )}
            
                {type === "sell"  && detail.status === "pair" && ( 
                    <div>
                        <div className="mining_slot" onClick={(event) => props.onClick(detail)} >
                            <div className="bar_slot_info">
                                <img className="bar_slot_info_bg" src={assets.BAR_SLOT_INFO} width={100}/>
                                {detail.appeal == 2 && (
                                    <span>{t("Freeze...")}</span>
                                )} 
                                {detail.appeal == 1 && (
                                    <span className="status-bar">
                                        <span className="status-icon"><img src={assets.ICON_ALERT} width={30}/></span>
                                        <span className="status-alert">{t("Time Up!!")}</span>
                                    </span>
                                ) }
                                 {detail.appeal == 0 && (
                                    <span>{t("Matched")}</span>
                                )} 
                            </div>
                            {detail.appeal == 2 ? (
                                <div className="mineType">   
                                    <img className="mineTypeBg" src={assets.SILVERLVL_COMPLETE_LOCK} width={100}/>
                                </div>
                            ) : (
                                <div className="mineType">   
                                    <img className="mineTypeBg" src={assets.SILVERLVL_COMPLETE} width={100}/>
                                </div>
                            )}
                            {detail.appeal == 0 && (  
                                <div className="mineAction">  
                                    <Countdown initialSeconds={detail.count_down} className="mineCoundown" /> 
                                </div>
                            )}
                        </div> 
                    </div> 
                )}
                {type === "selling"  && ( 
                    <div>
                        <div className="mining_slot" onClick={(event) => props.onClick(detail)} >
                            <div className="bar_slot_info">
                                <img className="bar_slot_info_bg" src={assets.BAR_SLOT_INFO} width={100}/>
                                {detail.status === "selling" ? (
                                    <span>{t("On Sale")}</span>
                                ) : (
                                    <span>{t("On Sale")}</span>
                                )}
                            </div>
                            <div className="mineType">  
                                <img className="mineTypeBg" src={assets.SILVERLVL_COMPLETE} width={100}/>
                            </div> 
                            <div className="mineAction">  
                            <Countdown initialSeconds={detail.matching_count_down} className="mineCoundown matching" />
                            </div>
                        </div>
                    </div> 
                )}
                {type === "sell_received_instant_pay" && detail.status === "paid" && ( 
                    <div>
                        <div className="mining_slot" onClick={(event) => props.onClick(detail)} >
                        <div className="bar_slot_info">
                            <img className="bar_slot_info_bg" src={assets.BAR_SLOT_INFO} width={100}/>
                            <span className="status-bar">
                                <span className="status-icon"><img src={assets.ICON_ALERT} width={30}/></span>
                                <span className="status-success">{t("Received")}</span> 
                            </span> 
                        </div>
                        <div className="mineType"> 
                            <img className="mineTypeBg" src={assets.SILVERLVL_COMPLETE} width={100}/>
                        </div>
                        <div className="mineAction"> 
                            <span>{t("Please click")}</span>
                            {/* confirmReceiveMine = ( receivePackageId, receiveValue, receiveId ) */}
                        </div>
                        </div>
                    </div>
                )}  


                {type === "unpaid" && ( 
                    <div>
                        <div className="mining_slot" onClick={(event) => props.onClick(detail)} >
                            <div className="bar_slot_info">
                                <img className="bar_slot_info_bg" src={assets.BAR_SLOT_INFO} width={100}/>
                               
                                <span className="status-bar">
                                    <span className="status-icon"><img src={assets.ICON_ALERT} width={30}/></span>
                                    <span className="status-alert">{t("Time Up!!")}</span>
                                </span>
                        
                            </div>
                            <div className="mineType">   
                                <img className="mineTypeBg" src={assets.SILVERSTARTER} width={100}/>
                            </div> 
                            <div className="mineAction">  
                                {detail.value} <img src={assets.USDT_BTN} height={20} /> 
                            </div> 
                        </div> 
                    </div> 
                )}

                {type === "pending" && ( 
                    <div>
                        <div className="mining_slot" onClick={(event) => props.onClick(detail)} >
                            <div className="bar_slot_info">
                                <img className="bar_slot_info_bg" src={assets.BAR_SLOT_INFO} width={100}/>
                               
                                <span className="status-bar">
                                    {t("Please reserve")}
                                </span>
                        
                            </div>
                            <div className="mineType">   
                                <img className="mineTypeBg" src={assets.SILVERLVL_COMPLETE_LOCK} width={100}/>
                            </div> 
                            <div className="mineAction">   
                            </div> 
                        </div> 
                    </div> 
                )}  
            </div>
        </span>  
    );
}
