import React, {useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";    
  
import { useTranslation } from 'react-i18next';  
import { post } from '../../Services/api_helper'  
import { parseMessage, maskEmail, setLocalStorageItems, updateSlotLocalStorage, updateWalletLocalStorage, uuBalance } from '../../Services/Utils'  
import ModalService from './ModalService';
import ModalBox from './ModalBox'
import { authApiCall , createApiCall }  from './createApiCall'
import { MuiTelInput } from 'mui-tel-input'
 
import assets from '../../assets.json';

import { 
    Button, 
    FormGroup, 
    Input, 
    InputGroupText,
    InputGroup, 
} from "reactstrap"; 
    
import { Notyf } from "notyf"; 

const notyf = new Notyf({duration: 5000}); 

export default function Login() {   
 
    const { t } = useTranslation();
 
    const [loading, setLoading] = React.useState(false)  
    const [isDisabled, setIsDisabled] = useState(true);  
    const [supportUrl, setSupportUrl] = useState(''); 
    const [ showPs, setShowPs] = useState(false);  
    const [email, setEmail] = useState('');   
    const [password, setPassword] = useState('');    
  
    const { token, setToken } = useState('');   
    const navigate = useNavigate();

    useEffect(() => { 
        if(token){ 
            navigate('/home');
        }  
        getCountry();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
 
    const handleLogin = async () => {   
        const apiCall = authApiCall("mobileLogin", { 
            email: email, 
            password: password
        }, (response) => {
            localStorage.setItem('token', response.token); 
            loadSetting() 
        });
        await apiCall(navigate, t); 
    }  

    const [total_bonus, setTotal_bonus] = useState(localStorage.getItem('bonus') || 0);
    const [total_usdt, setTotal_usdt] = useState(localStorage.getItem('usdt') || 0);
    const [total_miner, setTotal_miner] = useState(localStorage.getItem('miner') || 0);
        
    const  updateBalance = () => { 
        const updatedValues = uuBalance();
        if (updatedValues) {
            if (updatedValues.bonus) {
                setTotal_bonus(updatedValues.bonus);
            }
            if (updatedValues.usdt) {
                setTotal_usdt(updatedValues.usdt);
            }
            if (updatedValues.miner) {
                setTotal_miner(updatedValues.miner);
            }
        }
    }

    const loadSetting =  async () => {
        const apiCall = createApiCall("login", {}, (response) => { 
            let member_details= response.data.member_details  
            localStorage.setItem("lang", member_details.language);
            setLocalStorageItems(member_details);
            updateBalance(); 
            goto("home");
        })
        await apiCall(navigate, t);
    };

    const inputChangeHandler = (setFunction, event) => {  
        setFunction(event.target.value)
    }     

    useEffect(() => {
        setIsDisabled(email === '' || password === ''  ); 
    }, [email, password]);
  
    const support = (e) => { 
        e.preventDefault();
        window.open(supportUrl, '_blank'); 
    };
   
    const goto = (path) => { 
        navigate('/' + path);
    }; 
 
    const toggleShowPs = () => {
        setShowPs((showPs) => !showPs); 
    };
     
    const [isOpenRegister, setIsOpenRegister] = useState(false);
    const [isOpenOtp, setIsOpenOtp] = useState(false);
    const [canSubmitOtp, setCanSubmitOtp] = useState(false);
    const [canSubmitRegister, setCanSubmitRegister] = useState(false);
    const [sendOtp, setSendOtp] = useState(false);
    const [countdown, setCountdown] = useState(0);
 
    const [regemail, setRegemail] = useState("");
    const [regpassword, setRegpassword] = useState("");
    const [password_confirmation, setPassword_confirmation] = useState("");
    const [mobile_no, setMobile_no] = useState("");
    const [referral_code, setReferral_code] = useState("");
    const [country_iso, setCountry_iso] = useState("US");
    const [otp, setOtp] = useState(""); 
    const [allowedCountries, setAllowedCountries] = useState(["US"]);
    const [time] = useState(10);
 
    const handleOpenRegister = () => {  
        setIsOpenRegister(true);  
    };
    const handleCloseRegister = () => {
        setIsOpenRegister(false);
    }; 
    
    const getCountry = async () => { 
        const apiCall = authApiCall("otp/countryList", {  
        }, (response) => { 
            if(response.result === 1){     
                setAllowedCountries(response.country);  
            }
        });
        await apiCall(navigate, t); 
    }; 

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const sendOtpForm = async () => {
        if (!isValidEmail(regemail)) { 
            notyf.error(t("Please enter a valid email address.")); 
            return false;
        } 

        const apiCall = createApiCall("otp/request", {
            type: "register", 
            email: regemail
        }, (response) => {
            if(response.result === 1){    
                handleOpenRegister(); 
                setSendOtp(true)
                setCountdown(time);
            }
        });
        await apiCall(navigate, t);
    };

    const sendRegisterForm = async (e) => {
        e.preventDefault();      
        if(regpassword !==  password_confirmation)
        {
            notyf.error(t("Invalid password confirmation")); 
            return false;
        }

        let country_iso2 = country_iso;
        if(country_iso2 == null){
            country_iso2 = "US"
        }
        const apiCall = authApiCall("register", { 
            email: regemail,
            password: regpassword,
            password_confirmation: password_confirmation,
            mobile_no: mobile_no,
            referral_code: referral_code,
            country_iso: country_iso2,
            otp: otp,
        }, (response) => {
            if(response.result === 1){     
                ModalService.showModal(<div>{t("Register successful!!")}</div>,{type: "Happy"});
                setIsOpenRegister(false);
            }
        });
        await apiCall(navigate, t);
    };
 
    const handlePhoneChange = (value, info) => { 
        setCountry_iso(info.countryCode)
        setMobile_no(info.numberValue) 
    }
 
    useEffect(() => {
        if (sendOtp) {
            const timer = setTimeout(() => {
            setSendOtp(false);
            }, ((time+1) * 1000)); // 15 seconds

            return () => {
                clearTimeout(timer);
            };
        }
    }, [sendOtp]);

    useEffect(() => {
        if (sendOtp) {
            const interval = setInterval(() => {
                setCountdown(countdown - 1);
            }, 1000); // 1 second

            return () => {
                clearInterval(interval);
            };
        }
    }, [sendOtp, countdown]);
         
    useEffect(() => { 
        if(regemail && sendOtp === false){
            setCanSubmitOtp(true);
        }else{
            setCanSubmitOtp(false);
        } 
    }, [regemail, sendOtp]); 
     
    // country_iso: country_iso,
    useEffect(() => { 
        // Check if all the required fields are truthy (not null, undefined, or empty string)
        const canSubmit = [password_confirmation, regemail, regpassword, mobile_no, referral_code].every(Boolean);
    
        setCanSubmitRegister(canSubmit);
    }, [password_confirmation, regemail, regpassword, mobile_no, referral_code]);
     
    return( 
        <div className="login">  
            <div className="login-wrapper">   
                <img src={assets.LOGO} width={120} />  
                <div 
                    className="form-box" 
                    style={{ backgroundImage: `url(${assets.BORDER_GEM_INFO})`}}
                >   {}
                    <div className="form" >   
                        <FormGroup className="form-row mb-3">
                            <label>{t("Email Address")}</label>
                            <InputGroup 
                                className="input-group-alternative"  
                                style={{ backgroundImage: `url(${assets.PASSWORD_INPUT_BG})`}} 
                            >  
                            <Input 
                                placeholder={t("Enter Email")}  
                                name="email"
                                type="email"
                                value={email}
                                onChange={(e)=>inputChangeHandler(setEmail, e)}    
                            />
                            </InputGroup>
                        </FormGroup> 
                        <FormGroup className="form-row mb-3">
                            <label>{t("Login Password")}</label>
                            <InputGroup 
                                className="input-group-alternative"  
                                style={{ backgroundImage: `url(${assets.PASSWORD_INPUT_BG})`}} 
                            >  
                            <Input 
                                placeholder={t("Enter Password")}  
                                name="password"
                                type={showPs ? 'text' : 'password'}
                                value={password}
                                onChange={(e)=>inputChangeHandler(setPassword, e)}    
                            />
                            <InputGroupText  onClick={toggleShowPs}>
                            {showPs ?(
                                    <i className="fas fa-eye"></i>
                                ): (
                                <i className="fas fa-eye-slash"></i> 
                            )}
                            </InputGroupText> 
                            </InputGroup>
                        </FormGroup> 
    
                        <div className="form-add-action">  
                            <span  onClick={support} >
                            {t("Forgot Password?")}
                            </span>
                        </div>
                        <div className="form-action btn-block">  
                            <Button 
                                type="submit" disabled={isDisabled}
                                onClick={() => handleLogin()}
                                style={{
                                    backgroundImage: isDisabled ? `url(${assets.BUTTON_GREY})`:`url(${assets.SUBMIT_BTN})` 
                                }}
                                >{t('Login')}</Button> 
                        </div>     
                    </div> 
                </div>  
                
                <div className="nav-reg">  
                    {t('Not a member yet?')} <span onClick={handleOpenRegister}>{t("Register Now")}</span> 
                </div>    
            </div>
 
            <ModalBox
                isOpen={isOpenRegister}
                onClose={handleCloseRegister}
                handleClose={handleCloseRegister}
                title={t("Registration")}
                className={"modal_long"}
            >
                <div className="modal-form">
                    <div className="modal-header">
                        <h4>{t("Registration")}</h4>
                        <p>{t("Please key in your email address to receive OTP code")}</p>
                    </div>
                    <form className="register-form" onSubmit={sendRegisterForm} >  
                        <div className="modal-form-content">
                            <FormGroup className="form-row mb-3">
                                <label>{t("Email Address")}</label>
                                <InputGroup 
                                    className="input-group-alternative"   
                                    style={{ backgroundImage: `url(${assets.PASSWORD_INPUT_BG})`}} 
                                >  
                                    <Input 
                                        placeholder={t("Enter Email")}  
                                        name="email"
                                        type="email"
                                        value={regemail}
                                        onChange={(e)=>inputChangeHandler(setRegemail, e)}    
                                        required
                                    />
                                    <span className="btn btn-request"> 
                                        <button type="button" className="btnSubmit" disabled={!canSubmitOtp} onClick={sendOtpForm}>
                                            <img src={assets.SUBMIT_BTN} width={100} />
                                            <span>{t("Request")}</span>
                                            <span className="btn-remark"> {countdown > 0 ? `(${countdown} seconds)` : ''}</span>
                                        </button> 
                                    </span> 
                                </InputGroup>
                            </FormGroup> 
                            <FormGroup className="form-row mb-3">
                                <label>{t("OTP")}</label>
                                <InputGroup 
                                    className="input-group-alternative"   
                                    style={{ backgroundImage: `url(${assets.PASSWORD_INPUT_BG})`}} 
                                >  
                                    <Input 
                                        placeholder={t("OTP")}  
                                        name="otp"
                                        type="text"
                                        value={otp}
                                        onChange={(e)=>inputChangeHandler(setOtp, e)}   
                                        required 
                                    /> 
                                </InputGroup>
                            </FormGroup> 
                            <FormGroup className="form-row mb-3 input-phone">
                                <label>{t("Phone Number")}</label>
                                <InputGroup 
                                    className="input-group-alternative"   
                                    style={{ backgroundImage: `url(${assets.PASSWORD_INPUT_BG})`}} 
                                >  
                                <MuiTelInput
                                    required
                                    variant="standard"
                                    defaultCountry={'US'} // Set the default country (optional)
                                    label={false}
                                    value={mobile_no}
                                    onChange={handlePhoneChange}
                                    autoComplete="off"
                                    onlyCountries={allowedCountries} 
                                    
                                />  
                                </InputGroup>
                            </FormGroup> 
                            <FormGroup className="form-row mb-3">
                                <label>{t("Referral Code")}</label>
                                <InputGroup 
                                    className="input-group-alternative"   
                                    style={{ backgroundImage: `url(${assets.PASSWORD_INPUT_BG})`}} 
                                >  
                                    <Input 
                                        placeholder={t("Referral Code")}  
                                        name="referral_code"
                                        type="text"
                                        value={referral_code}
                                        onChange={(e)=>inputChangeHandler(setReferral_code, e)}   
                                        required 
                                    /> 
                                </InputGroup>
                            </FormGroup> 
                            <FormGroup className="form-row mb-3">
                                <label>{t("Login Password")}</label>
                                <InputGroup 
                                    className="input-group-alternative"   
                                    style={{ backgroundImage: `url(${assets.PASSWORD_INPUT_BG})`}} 
                                >  
                                    <Input 
                                        placeholder={t("Login Password")}  
                                        name="regpassword"
                                        type="password"
                                        value={regpassword}
                                        onChange={(e)=>inputChangeHandler(setRegpassword, e)} 
                                        required   
                                    /> 
                                </InputGroup>
                            </FormGroup> 
                            <FormGroup className="form-row mb-3">
                                <label>{t("Confirm Login Password")}</label>
                                <InputGroup 
                                    className="input-group-alternative"   
                                    style={{ backgroundImage: `url(${assets.PASSWORD_INPUT_BG})`}} 
                                >  
                                    <Input 
                                        placeholder={t("Confirm Login Password")}  
                                        name="password_confirmation"
                                        type="password"
                                        value={password_confirmation}
                                        onChange={(e)=>inputChangeHandler(setPassword_confirmation, e)} 
                                        required   
                                    /> 
                                </InputGroup>
                            </FormGroup> 
                        </div>
                        <div className="transfer-footer">
                            <button type="submit" className="btnSubmit" disabled={!canSubmitRegister}>
                                <img src={assets.SUBMIT_BTN} width={100} />
                                <span>{t("Submit")}</span>
                            </button>
                        </div>
                    </form>
                </div>
            </ModalBox> 
  

        </div>
        
    );
} 