import React from 'react';

import assets from '../../assets.json';

const ModalBox = ({
  isOpen,
  children, 
  handleClose, 
  className = null
}) => { 
  return ( 
    <div className={`modal-overlay ${isOpen ? 'fade-in' : 'fade-out'} ${className ? className : ''}`} >  
         <div className="modal-content"  
         style={{ backgroundImage: `url(${assets.BORDER_GEM_INFO})`}}
         > 
            {children}
            <span className="close-button" onClick={handleClose}>
                <img src={assets.BTN_CLOSE} width={60} alt={"close"}/>
            </span>
        </div> 
    </div>
  );
};

export default ModalBox;
