
import React, {useEffect,useState,useRef,useContext } from 'react';

import { useNavigate } from 'react-router-dom'    
   
import useToken from '../App/useToken';
import { useTranslation } from 'react-i18next'; 
 
import { Progress } from 'react-vant';
import useLocalStorage from './useLocalStorage';
import { parseMessage, maskEmail, setLocalStorageItems, getValue, updateWalletLocalStorage, uuBalance } from '../../Services/Utils'  
import Footer from './Footer';  
import { post } from '../../Services/api_helper'  
import ModalService from './ModalService';
import  { createApiCall }  from './createApiCall'
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import ModalBox from './ModalBox' 
import { AudioProvider, AudioContext } from '../AudioContext';


import WalletModal from './WalletModal' 
import RedeemModal from './RedeemModal' 
import PurchaseModal from './PurchaseModal';
import WithdrawalFormModal from './WithdrawalFormModal';
 
import assets from '../../assets.json';

import { 
    Button, 
    FormGroup, 
    Input, 
    InputGroupText,
    InputGroup, 
} from "reactstrap"; 
export default function Profile() {  
    const inputRef = useRef(null);

    const { t } = useTranslation();
    const { i18n } = useTranslation(); 
    const navigate = useNavigate();  
  
    const storedSettings = JSON.parse(localStorage.getItem('gameSetting'));  
    const memberDetails = JSON.parse(localStorage.getItem('memberDetails'));

    const getSettingByKey = (key) => {
       if(storedSettings){ 
           const setting = storedSettings.find(item => item.key === key);
           return setting ? setting.value : null; 
        }
        return null;
    };

    const getMemberByKey = (key) => {
        return memberDetails && memberDetails[key] ? memberDetails[key] : null;
    };

    const [redeemInfo, setRedeemInfo ] = useState([]);
   
    const fetchRedeemInfo =  async () => {
        const apiCall = createApiCall("package/getRedeemInfo", {}, (response) => { 
            let data = response.redeemInfo;
            if (data.length > 0) {
              setRedeemInfo(data[0]);
            } 
        })
        await apiCall(navigate, t);
    }; 
     
    useEffect(() => {  
        loadSetting()
        summaryReport()
        fetchRedeemInfo() 
        subAccountGet() 
    }, []); 
  

    const [email] = useLocalStorage('username', "test@test.com");
    const [referral] = useLocalStorage('refferral_code', "xxxxxx"); 
    const [total_bonus, setTotal_bonus] = useState(localStorage.getItem('bonus') || 0);
    const [total_usdt, setTotal_usdt] = useState(localStorage.getItem('usdt') || 0);
    const [total_miner, setTotal_miner] = useState(localStorage.getItem('miner') || 0);
    const [transactionPasswordOtp, setTransactionPasswordOtp] = useState(null);  
        
    const  updateBalance = () => { 
        const updatedValues = uuBalance();
        if (updatedValues) {
            if (updatedValues.bonus) {
                setTotal_bonus(updatedValues.bonus);
            }
            if (updatedValues.usdt) {
                setTotal_usdt(updatedValues.usdt);
            }
            if (updatedValues.miner) {
                setTotal_miner(updatedValues.miner);
            }
        }
    }
 
    useEffect(() => {
        const intervalId = setInterval(() => {
            updateBalance()
        }, 10000); // 10 seconds
    
        return () => {
          clearInterval(intervalId);
        };
    }, []);

    const [mineProfit, setMineProfit] = useState(0);   
    const [mineValue, setMineValue] = useState(0);   
    const [teamReward, setTeamReward] = useState(0);   
 
    const credit = getValue('credit', 0);
    const credit_progress = getValue('credit_progress', 0);
    const credit_up = getValue('credit_up', 0);

    const transaction = credit_progress+"/"+credit_up;
    const totalStars = 5;
    const onStars = Math.min(credit, totalStars);
    const offStars = totalStars - onStars;

    const onStarsArray = Array(onStars).fill(assets.STAR_ON);
    const offStarsArray = Array(offStars).fill(assets.STAR_OFF);
    
    const starsArray = [...onStarsArray, ...offStarsArray];

    const rank = getValue('rank', 0);
      
    const loadSetting =  async () => {
        const apiCall = createApiCall("login", {}, (response) => { 
            let member_details= response.data.member_details 
            setLocalStorageItems(member_details);
            updateBalance();
        })
        await apiCall(navigate, t);
    };
    const summaryReport =  async () => {
        const apiCall = createApiCall("transaction/summaryReport", {}, (response) => { 
            let summary= response.data[0] 
            
            setMineProfit(summary.totalMineProfit);
            setMineValue(summary.totalMineValue);
            setTeamReward(summary.totalTeamReward);  
        })
        await apiCall(navigate, t);
    };
    
    const copyToClipboard  = (email, type) => { 
        // Create a new text area element
        const textArea = document.createElement('textarea');
        textArea.value = email;

        // Append the text area to the DOM
        document.body.appendChild(textArea);

        // Select and copy the text from the text area
        textArea.select();
        document.execCommand('copy');

        // Clean up
        document.body.removeChild(textArea);

        Notify.success(parseMessage(t("%type have copy to clipboard"),{"%type": t(type)}));   
    }; 

    const rankImages = {
        0: assets.RANK_1,
        1: assets.RANK_2,
        2: assets.RANK_3,
        3: assets.RANK_4,
        4: assets.RANK_5,
        5: assets.RANK_6,
    };
    
    const rankTitle = {
        0: t("Regular"),
        1: t("Silver"),
        2: t("Platinum"),
        3: t("Gold"),
        4: t("Diamond"),
        5: t("Bronze"),
    };

    const [settingModal, setSettingModal] = useState(false);   
    const [subaccountModal, setSubaccountModal] = useState(false);   
    const [securityModal, setSecurityModal] = useState(false);   
    const [logoutModal, setLogoutModal] = useState(false);   
    const [changeTransactionPasswordModal, setChangeTransactionPasswordModal] = useState(false);  
    const [changePasswordModal, setChangePasswordModal] = useState(false);  
    const [lang, setLang] = useState(localStorage.getItem('lang'), "en");   
    const [confirmLoginPassword, setConfirmLoginPassword] = useState(null);  
    const [currentLoginPassword, setCurrentLoginPassword] = useState(null);  
    const [newLoginPassword, setNewLoginPassword] = useState(null);  
    const [canSubmitChangePassword, setCanSubmitChangePassword] = useState(false);  
    const [confirmTransactionPassword, setConfirmTransactionPassword] = useState(null);  
    const [newTansactionPassword, setNewTansactionPassword] = useState(null);  
    const [canSubmitChangeTrasactionPassword, setCanSubmitChangeTrasactionPassword] = useState(false);  
    const [subaccountLists, setSubaccountLists] = useState([]); 

    const MemberStatus = ({ status }) => (
        <span className="member-status">
        {status === "active" ? (
            <img src={assets.ICON_ACTIVE} width={15} />
        ) : (
            <img src={assets.ICON_IN_ACTIVE} width={15} />
        )}
        </span>
    );
    
    const BadgeActive = ({ children }) => (
        <span className="badge-active">{children}</span>
    );

    const imageSources = {
        error: assets.ERROR,
        success: assets.SUCCESS,
        happy: assets.HAPPY,
        excited: assets.EXCITED,
        annoying: assets.ANNONYING,
    };
    
    const imageAltTexts = {
        error: 'Error',
        success: 'Success',
        happy: 'Happy',
        excited: 'excited',
        annoying: 'Annoying'
    };

    const inputChangeHandler = (setFunction, event) => {  
        setFunction(event.target.value) 
    }     
    
    const confirmLogout = () => {
        clearLocalStorage(); 
        navigate('/login');
    }

    const clearLocalStorage = () => { 
        if (typeof window !== 'undefined') { 
            localStorage.clear();
        }
    };

    const languages = [ 
        { value: "en", label: t("English") },
        { value: "zh", label: t("Chinese") }, 
    ];

    const handleUpdateLang = (e) => {    
        e.preventDefault();      
        let newLang = e.target.value;
        setLang(newLang);
        i18n.changeLanguage(newLang); 
        localStorage.setItem('lang', newLang);  
        handleChangeLanguageFormSubmit(newLang)
    } 
    const handleChangeLanguageFormSubmit = async (newLang) => { 
        const apiCall = createApiCall("language/change", {
            language: newLang 
        }, (response) => { 
        });
        await apiCall(navigate, t);
    };

    const handleChangePasswordFormSubmit = async (e) => {

        e.preventDefault();      
        if(confirmLoginPassword !==  newLoginPassword)
        { 
            Notify.failure(t("Invalid password confirmation")); 
            return false;
        }
 
        const apiCall = createApiCall("password/login/change", {
            password_old: currentLoginPassword, 
            password_new: newLoginPassword,
            password_new_confirmation: confirmLoginPassword
        }, (response) => {
            if(response.result === 1){    
                ModalService.showModal(<div>{t("Login Password Changed Successful!!")}</div>,{type: "happy"}); 
                setCurrentLoginPassword(null); 
                setNewLoginPassword(null); 
                setConfirmLoginPassword(null); 
                setChangePasswordModal(false); 
                setSecurityModal(false); 
                inputRef.current.value = '';
            } else { 
                ModalService.showModal(<div>{t(response.message_code)}</div>,{type: "error"}); 
            }
        });
        await apiCall(navigate, t);
    };
    
    const handleChangeTransactionPasswordFormSubmit = async (e) => {

        e.preventDefault();      
        if(confirmTransactionPassword !==  newTansactionPassword)
        { 
            Notify.failure(t("Invalid password confirmation")); 
            return false;
        }
 
        const apiCall = createApiCall("password/transaction/reset", {
            otp: transactionPasswordOtp, 
            transaction_password: newTansactionPassword,
            transaction_password_confirmation: confirmTransactionPassword
        }, (response) => {
            if(response.result === 1){    
                ModalService.showModal(<div>{t("Transaction Password Changed Successful!!")}</div>,{type: "happy"}); 
                setTransactionPasswordOtp(null); 
                setConfirmTransactionPassword(null); 
                setNewTansactionPassword(null); 
                setChangeTransactionPasswordModal(false); 
                setSecurityModal(false); 
                inputRef.current.value = '';
            } else { 
                ModalService.showModal(<div>{t(response.message_code)}</div>,{type: "error"}); 
            }
        });
        await apiCall(navigate, t);
    };
  
    const subAccountGet =  async () => {
        const apiCall = createApiCall("subAccount/get", {}, (response) => {
            setSubaccountLists(response.accounts); 
        })
        await apiCall(navigate, t);
    };  
 
    const swapAccount = (username) => {  
        subAccountLogin(username);
    } 
    
    const subAccountLogin =  async (username) => {
        const apiCall = createApiCall("subAccountLogin", {
            username: username, 
            source: "api",
        }, (response) => {
            clearLocalStorage();
            Notify.success(t("Successful login to account:")+username);   
            localStorage.setItem('token', response.token);

            loadSetting()
            setTimeout(() => {
                navigate('/home');
              }, 1000);
        })
        await apiCall(navigate, t);
    };

    useEffect(() => {
        setCanSubmitChangePassword(
          confirmLoginPassword !== null &&
          currentLoginPassword !== null &&
          newLoginPassword !== null
        ); 
    }, [confirmLoginPassword, currentLoginPassword, newLoginPassword]);

    useEffect(() => {
        setCanSubmitChangeTrasactionPassword(
            transactionPasswordOtp !== null &&
            confirmTransactionPassword !== null &&
            newTansactionPassword !== null
        );  
    }, [confirmTransactionPassword, transactionPasswordOtp, newTansactionPassword]);




    const [usdtModal, setUsdtModal] = useState(false);   
    const [minerModal, setMinerModal] = useState(false);   
    const [bonusModal, setBonusModal] = useState(false);    
    const [withdrawalModal, setWithdrawalModal] = useState(false);   
    const [withdrawalConfirmModal, setWithdrawalConfirmModal] = useState(false);  
    const [passwordModal, setPasswordModal] = useState(false);
    const [otpType, setOtpType] = useState(null);   
    const [canSubmitPassword, setCanSubmitPassword] = useState(false);  
 
    const [time] = useState(10);
    const [canSubmitOtp, setCanSubmitOtp] = useState(false);
    const [sendOtp, setSendOtp] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [withdrawalAmount, setWithdrawalAmount] = useState(0);
    const [withdrawalFee, setWithdrawalFee] = useState(0);
    const [withdrawalFinal, setWithdrawalFinal] = useState(0);

    const [redeemProgress, setRedeemProgress] = useState(0);
    const [canSubmitRedeem, setCanSubmitRedeem] = useState(false);
    const [transactionPassword, setTransactionPassword] = useState("");
 
    const [amountError, setAmountError] = useState("");
    const [wMinAmount, setWMinAmount] = useState(0);
    const [wMaxAmount, setWMaxAmount] = useState(0);
    const [walletAddress, setWalletAddress] = useState(null);
    const [canConfirmWithdrawal, setCanConfirmWithdrawal] = useState(false);
    const [transactionPasswordFormType, setTransactionPasswordFormType] = useState(null);
    const [minerQty, setMinerQty] = useState(0);
    const [minerError, setMinerError] = useState(null);
    const [canConfirmMiner, setCanConfirmMiner] = useState(false);
 
    let trc20_wallet_address = getMemberByKey("trc20_wallet_address") || null;  
    let usdt_wallet_address = getMemberByKey("usdt_wallet_address") || null; 
   
    useEffect(() => {   
        let progress = parseFloat(total_bonus)/parseFloat(redeemInfo.value)*100;
        if(progress > 100){
            progress = 100;
        }
        setRedeemProgress(progress); 
        
        if(redeemInfo.redeem_count < redeemInfo.maximum_redeem && total_bonus >= redeemInfo.value){ 
            setCanSubmitRedeem(true);
        } else {
            setCanSubmitRedeem(false);
        }

    }, [redeemInfo]); 
  
    useEffect(() => { 
        if(transactionPassword){
            setCanSubmitPassword(true);
        }else{
            setCanSubmitPassword(false);
        } 
    }, [transactionPassword]); 
     
    useEffect(() => {
        if (sendOtp) {
            const timer = setTimeout(() => {
            setSendOtp(false);
            }, ((time+1) * 1000)); // 15 seconds

            return () => {
                clearTimeout(timer);
            };
        }
    }, [sendOtp]);

    useEffect(() => {
        if (sendOtp) {
            const interval = setInterval(() => {
                setCountdown(countdown - 1);
            }, 1000); // 1 second 
            return () => {
                clearInterval(interval);
            };
        }
    }, [sendOtp, countdown]);
         
    useEffect(() => { 
        if(sendOtp === false){
            setCanSubmitOtp(true);
        }else{
            setCanSubmitOtp(false);
        } 
    }, [ sendOtp]); 
         
    useEffect(() => { 
        if( changeTransactionPasswordModal ){ 
            setOtpType("forgot_trans_password")
        } 
        else if( withdrawalModal ){ 
            setOtpType("withdrawal")
        }
        else{
            setOtpType(null)
        } 
    }, [ changeTransactionPasswordModal, withdrawalModal ]); 
  
    useEffect(() => { 
       let withdrawalMinAmount = getSettingByKey("usdt_withdrawal_min") || 0; 
       let withdrawalMaxAmount = getSettingByKey("usdt_withdrawal_max") || 0;
       let usdt_withdrawal_charges = getSettingByKey("usdt_withdrawal_charges") || 0;
       setWMinAmount(withdrawalMinAmount);
       setWMaxAmount(withdrawalMaxAmount);
       setWithdrawalFee(usdt_withdrawal_charges);

        let withdrawalAmount2 = parseFloat(withdrawalAmount);
        let withdrawalMinAmount2 = parseFloat(withdrawalMinAmount);
        let withdrwalMaxAmount2 = parseFloat(withdrawalMaxAmount);
        
        if(withdrawalAmount2 < withdrawalMinAmount2){
            setCanConfirmWithdrawal(false);
            setAmountError(parseMessage(t("min withdrawal amount: %amount USDT"), {"%amount": withdrawalMinAmount2}));
        }
        else if(withdrawalAmount2 > withdrwalMaxAmount2){
            setCanConfirmWithdrawal(false);
            setAmountError(parseMessage(t("max withdrawal amount: %amount USDT"), {"%amount": withdrwalMaxAmount2 }));
        } 
        else{
            setAmountError("");
            if(transactionPasswordOtp && walletAddress){
                setCanConfirmWithdrawal(true);
            } else {
                setCanConfirmWithdrawal(false);
            }
        } 


       setWithdrawalFinal(withdrawalAmount - withdrawalFee);
        
    }, [withdrawalAmount, transactionPasswordOtp, walletAddress]); 
 
    useEffect(() => {  
        if(minerQty <= 0){
            setCanConfirmMiner(false);
            setMinerError(t("Min miner to purchase: 1"));
        } 
        else{
            let requestUsdt = minerQty * 1;
            setMinerError("");
            if( parseFloat(requestUsdt) > parseFloat(total_usdt) ){
                setMinerError(t("Balance not enough"));
                setCanConfirmMiner(false);
            } else {
                setCanConfirmMiner(true);
                setMinerError("");
            }
        }   
    }, [minerQty]); 
 
    const sendOtpForm = async () => { 
        const apiCall = createApiCall("otp/request", {
            type: otpType, 
            email: email
        }, (response) => {
            if(response.result === 1){     
                setSendOtp(true) 
                setCountdown(time);
                Notify.success(t("OTP have send to your email"));   
            }
        });
        await apiCall(navigate, t);
    };
 
    const confirmWithdrawalFormSubmit = async (e) => { 
        e.preventDefault(); 
        if(parseFloat(withdrawalAmount) > parseFloat(total_usdt))
        { 
            Notify.failure(t("Not enought USDT to withdraw")); 
            return false;
        }
        setWithdrawalConfirmModal(true);
        setWithdrawalModal(false);
  
    };

    const handleConfirmWithdrawalButton = () => {
        setTransactionPasswordFormType("withdrawal");
        setPasswordModal(true);
    }

    const confirmPassword = () => {

        if(transactionPasswordFormType === "withdrawal"){
            handleConfirmWithdrawalFormSubmit()
        } else if(transactionPasswordFormType === "miner"){
            handleConfirmMinerFormSubmit()
        }
    }
 
    const handleConfirmWithdrawalFormSubmit = async () => {

        const apiCall = createApiCall("member/withdrawUsdt", {
            type: "trc20", 
            amount: withdrawalAmount,
            wallet_address: walletAddress,
            amount: withdrawalAmount,
            transaction_password: transactionPassword,
            otp: transactionPasswordOtp,
        }, (response) => {
            if(response.result === 1){    
                ModalService.showModal(<div>{t("Withdrawal Successful!!")}</div>,{type: "happy"}); 
                setTransactionPasswordOtp(null); 
                setWalletAddress(null); 
                setWithdrawalAmount(null); 
                setTransactionPassword(false); 
                setWithdrawalConfirmModal(false); 
                setPasswordModal(false); 

                updateWalletLocalStorage(response.wallet);
                updateBalance();
                inputRef.current.value = '';
            } else {  
                if(response.message_code === "401"){
                    ModalService.showModal(<div>{t("Wrong transaction password")}</div>,{type: "error"}); 
                } else {
                    ModalService.showModal(<div>{t(response.message_code)}</div>,{type: "error"}); 
                }
            }
        });
        await apiCall(navigate, t);
    };

    const handleConfirmMinerFormSubmit = async () => {

        const apiCall = createApiCall("wallet/topUpMiner", {
            amount: minerQty, 
            transaction_password: transactionPassword,
        }, (response) => { 
            if(response.result === 1){    
                ModalService.showModal(<div>{t("Purchase Successful!!")}</div>,{type: "happy"}); 
                let member_details= response.member 
                setLocalStorageItems(member_details);
                setPasswordModal(false);
                setMinerModal(false);
                inputRef.current.value = '';
            } else { 
                ModalService.showModal(<div>{t(response.message_code)}</div>,{type: "error"}); 
            }
        });
        await apiCall(navigate, t);
    };
 
    const handleWithdrawBtn = () => {
        setWithdrawalModal(true) 
        setUsdtModal(false) 
    }
     
    const confirmMinerForm = (e) => { 
        e.preventDefault();
        setTransactionPasswordFormType("miner");
        setPasswordModal(true);
    }
        
    const { audio } = useContext(AudioContext);
    const [audioCheckboxState, setAudioCheckboxState] = useState(localStorage.getItem('audio'));

    let bonusEffect = new Audio("/audio/bonus_collection.mp3");
    bonusEffect.volume = 1; 

    useEffect(() => { 
        if (audioCheckboxState === "1") {   
        } else {
            // Stop background music if playing
            if (bonusEffect) {
                bonusEffect.pause();
                bonusEffect.currentTime = 0;
            }
        }

        // Cleanup on unmount
        return () => {
            if (bonusEffect) {
                bonusEffect.pause();
                bonusEffect.currentTime = 0;
            }
        };
    }, [audioCheckboxState]);

    const confirmRedeemMine =  async (package_id) => {
        const apiCall = createApiCall("wallet/redeemMine", {
            package_id: package_id
        }, (response) => {
            if(response.result === 1){    
                ModalService.showModal(<div>{t("Redeem Successful!!")}</div>,{type: "excited"}); 
                        
                if (audioCheckboxState === "1") { 
                    let loopCount = 0;
                    const handleAudioEnded = () => {
                        loopCount++;
                        if (loopCount < 2) {
                            bonusEffect.play();
                        } else {
                            bonusEffect.removeEventListener('ended', handleAudioEnded);
                        }
                    };
                    bonusEffect.addEventListener('ended', handleAudioEnded);
                    bonusEffect.play();
                }
 
                loadSetting()
                summaryReport()
                fetchRedeemInfo() 
                subAccountGet() 
            } else {   
                ModalService.showModal(<div>{t(response.message_code)}</div>,{type: "error"}); 
            }
        })
        await apiCall(navigate, t);
    };
     
    const handleAudioCheckboxChange = (e) => {
        let audio1 = e.target.checked ? "1" : "0"; 
        setAudioCheckboxState(audio1);
        handleChangeAudioFormSubmit(audio1);
        localStorage.setItem('audio', audio1);   
    }; 

    const handleChangeAudioFormSubmit = async (audioCheckboxState) => { 
        const apiCall = createApiCall("member/updateAudit", {
            audio: audioCheckboxState 
        }, (response) => { 
        });
        await apiCall(navigate, t);
    };
    
    useEffect(() => {
        if (audio && audioCheckboxState === "1") {
            audio.play();
        } else if (audio) {
            audio.pause();
        }
    }, [audioCheckboxState, audio]);

    
    return (
    <>
        <div className="profile">   
            <div className="account-wrapper">   
                <div className="account-rank"> 
                    <img src={rankImages[rank]} width={70}  alt={t("rank")}/> 
                    <h4>{rankTitle[rank]}</h4> 
                </div>
                <div className="account-info"> 
                    <div className="account-info-box" style={{ backgroundImage: `url('${assets.BORDER_GEM_INFO}')` }}>
                        <div className="account-info-row">
                            <div className="account-info-item">
                                <h5>{email}</h5>
                            </div>
                            <span onClick={() => copyToClipboard(email,"email")}>
                                <img src={assets.BTN_COPY} width={20}/>
                            </span>
                        </div>
                        <div className="account-info-row">
                            <div className="account-info-item">
                                <label>{t("Referral ID")}</label>
                                <h6>{referral}</h6>
                            </div>
                            <span onClick={() => copyToClipboard(referral,"referral")}>
                                <img src={assets.BTN_COPY} width={20}/>
                            </span>
                        </div>
                        <div className="account-info-lists">
                            <div className="account-info-list-item">
                                {starsArray.map((src, index) => (
                                    <img key={index} src={`${src}`} width={30} alt="star" />
                                ))}
                            </div> 
                            <div className="account-info-list-item">
                                <Progress percentage="50" showPivot={false}/>
                            </div>
                            <div className="account-info-list-item">
                                <h6>{t("Transaction")}: {transaction}</h6> 
                                <h6>{t("Mine Profit")}: {mineProfit}</h6>
                                <h6>{t("Mine Value")}: {mineValue}</h6>
                                <h6>{t("Team Reward")}: {teamReward}</h6>
                            </div>
                        </div>

                    </div>  
                </div>  

                <div className="account-shortcut">
                    <div className="account-shortcut-wrapper">
                        <div 
                            className="account-shortcut-btn"
                            onClick={ () => setUsdtModal(true) } 
                        >
                            <img className="shortcut-btn-bg" src={assets.BUTTON_COIN_BASE} width={80}/>
                            <img className="shortcut-btn-icon" src={assets.USDT_BTN}  width={40} />
                            <span className="account-total">{total_usdt}</span>
                        </div> 
                        <div 
                            className="account-shortcut-btn"
                            onClick={ () => setMinerModal(true) } 
                        >
                            <img className="shortcut-btn-bg" src={assets.BUTTON_COIN_BASE} width={80}/>
                            <img className="shortcut-btn-icon" src={assets.MINER_BTN}  width={40}  />
                            <span className="account-total">{total_miner}</span>
                        </div> 
                        <div 
                            className="account-shortcut-btn"
                            onClick={ () => setBonusModal(true) } 
                        >
                            <img className="shortcut-btn-bg" src={assets.BUTTON_COIN_BASE} width={80}/>
                            <img className="shortcut-btn-icon" src={assets.COIN_BONUS}   width={40} />
                            <span className="account-total">{total_bonus}</span>
                        </div> 
                    </div> 
                </div> 

                <div className="account-nav">
                    <div 
                        className='btn-nav' 
                        style={{ backgroundImage: `url('${assets.PROFILE_BAR_GREY_BLUE}')` }}
                        onClick={ () => setSettingModal(true) } 
                    >
                        <span className="btn-nav-icon"><img src={assets.ICON_SETTING} height={25}/></span>
                        <span className="btn-nav-title">{t("settings")}</span>
                    </div>
                    <div 
                        className='btn-nav' 
                        style={{ backgroundImage: `url('${assets.PROFILE_BAR_GREY_BLUE}')` }}
                        onClick={ () => setSubaccountModal(true) } 
                    >
                        <span className="btn-nav-icon"><img src={assets.ICON_SWITCH} height={25}/></span>
                        <span className="btn-nav-title">{t("Sub Account")}</span>
                    </div>
                    <div 
                        className='btn-nav' 
                        style={{ backgroundImage: `url('${assets.PROFILE_BAR_GREY_BLUE}')` }}
                        onClick={ () => setSecurityModal(true) } 
                    >
                        <span className="btn-nav-icon"><img src={assets.ICON_SECURITY} height={25}/></span>
                        <span className="btn-nav-title">{t("Security")}</span>
                    </div>
                    <div 
                        className='btn-nav' 
                        style={{ backgroundImage: `url('${assets.PROFILE_BAR_GREY_BLUE}')` }}
                        onClick={ () => setWithdrawalModal(true) } 
                    >
                        <span className="btn-nav-icon"><img src={assets.ICON_WITHDRAWAL} height={25}/></span>
                        <span className="btn-nav-title">{t("Withdrawal")}</span>
                    </div>
                    <div 
                        className='btn-nav' 
                        style={{ backgroundImage: `url('${assets.PROFILE_BAR_GREY_BLUE}')` }}
                        onClick={ () => setLogoutModal(true) } 
                    > 
                        <span className="btn-nav-icon"><img src={assets.ICON_LOGOUT} height={25}/></span>
                        <span className="btn-nav-title">{t("Logout")}</span>
                    </div>
                </div> 
            </div> 
 
            {/*setLogoutModal */}
            <ModalBox
                isOpen={logoutModal} 
                handleClose={ () => setLogoutModal(false) }
                title={t("Logout")}
            > 
                <div className="modal-notif-content"> 
                    <div className="modal-icon"> 
                        <img 
                            src={imageSources["error"]} 
                            width={80} 
                            alt={imageAltTexts["error"] || 'Icon'} 
                        /> 
                    </div>
                    <h4>{t("Are You Sure Want To Logout?")}</h4>
                </div>
                <div className="modal-notif-footer">
                    <button className="btnSubmit" onClick={confirmLogout}>
                        <img src={assets.SUBMIT_BTN} width={100} />
                        <span>{t("Logout")}</span>
                    </button>
                </div>
            </ModalBox> 
 
            {/*setSettingModal(language) */}
            <ModalBox
                isOpen={settingModal} 
                handleClose={ () => setSettingModal(false) }
                title={t("Settings")}
            > 
                <div className="modal-notif-content">  
                    <div className="form-group input-music">
                        <label>{t("Music")}</label> 
                        
                        <input 
                            type="checkbox" 
                            value={1} 
                            onChange={handleAudioCheckboxChange} 
                            checked={ audioCheckboxState === "1"} 
                            className={ audioCheckboxState  === "1" ? 'checked fa-solid fa-check' : ''}
                        />
                    </div>
                    <select
                        value={lang}
                        onChange={handleUpdateLang}
                        className="filter-dropdown"
                        style={{ backgroundImage: `url('${assets.TEXT_INPUT_BG}')` }}
                    >
                        {languages.map((language) => (
                        <option key={language.value} value={language.value}>
                            {language.label}
                        </option>
                        ))}
                    </select>
                </div> 
            </ModalBox> 
  
            {/*setBonusModal */}
            <RedeemModal
                isOpen={bonusModal}
                handleClose={() => setBonusModal(false)}
                title="Redeem Bonus"
                redeemInfo={redeemInfo}
                redeemProgress={redeemProgress}
                totalBonus={total_bonus}
                canSubmitRedeem={canSubmitRedeem}
                onRedeemClick={() => confirmRedeemMine(redeemInfo.package_id)}
                silverStarterIcon={assets.SILVERSTARTER}
                goldStarterIcon={assets.GOLDSTARTER} 
            > 
            </RedeemModal>
  
            {/*setMinerModal */} 
            <PurchaseModal
                isOpen={minerModal}
                handleClose={() => setMinerModal(false)}
                title="Purchase Miner"
                onSubmit={confirmMinerForm}
                onChange={(e) => inputChangeHandler(setMinerQty, e)}
                inputRef={inputRef}
                minValue={1}
                maxValue={100} // adjust the max value as needed
                error={minerError}
                canSubmit={canConfirmMiner} 
                icon={assets.MINER_BTN}
                inputBg={assets.PASSWORD_INPUT_BG}
            > 
            </PurchaseModal> 
  
            {/*setUsdtModal */} 
            <WalletModal
                isOpen={usdtModal}
                handleClose={() => setUsdtModal(false)}
                title="USDT Balance" 
                walletAddress={trc20_wallet_address}
                disabled={true}
                onCopyClick={() => copyToClipboard(trc20_wallet_address, "TRC 20 Wallet address")}
                onWithdrawClick={handleWithdrawBtn}
                > 
            </WalletModal>

  
            {/*subaccountModal */}
            <ModalBox
                isOpen={subaccountModal} 
                handleClose={ () => setSubaccountModal(false) }
                title={t("Sub Account")}
                className={"modal-large"}
            > 
                <div className="modal-notif-content">  
                    <div className="list-members">  
                    {subaccountLists.length > 0 && (
                        subaccountLists.map((user, index) => (
                            
                            <div className="member-card"  key={index} title={index + 1} style={{ backgroundImage: `url('${assets.CARDBGB3}')` }}> 
                                <div className="member-card-left">   
                                    <div className="member-card-rank">  
                                        <img src={rankImages[user.rank]} width={50}  alt={t("rank")}/> 
                                        <h4>{rankTitle[user.rank]}</h4>
                                    </div> 
                                </div>
                                
                                <div className="member-card-right"> 
                                    <h4>
                                        {user.username === email ? (
                                            <span>{user.username}</span>
                                        ) : (
                                            <span onClick={() => swapAccount(user.username)}>{user.username}</span>
                                        )}
                                        <MemberStatus status={user.status} />
                                        {user.username === email && <BadgeActive>{t("USING")}</BadgeActive>}
                                    </h4>

                                    <div className="member-card-detail">
                                        <div className="member-card-info">
                                            <div>
                                                <img className="shortcut-btn-icon" src={assets.USDT_BTN} height={15} />: {user.wallet.usdt}
                                            </div>
                                            <div>
                                                <img className="shortcut-btn-icon" src={assets.MINER_BTN} height={15} />: {user.wallet.miner}
                                            </div>
                                            <div>
                                                <img className="shortcut-btn-icon" src={assets.COIN_BONUS} height={15} />: {user.wallet.bonus} 
                                            </div> 
                                        </div> 
                                    </div>
                                </div>
                            </div>  
                        ))
                        )};
                    </div> 
                </div> 
            </ModalBox> 

            {/*setSecurityModal */}
            <ModalBox
                isOpen={securityModal} 
                handleClose={ () => setSecurityModal(false) }
                title={t("Security")}
            > 
                <div className="modal-notif-content">  
                    <button 
                        className="btnPassword" 
                        onClick={ () => setChangePasswordModal(true) } 
                        style={{ backgroundImage: `url(${assets.PASSWORD_BTN_BG})` }}
                    > 
                        <span>{t("Change Password")}</span>
                    </button> 
                    <button 
                        className="btnPassword" 
                        onClick={ () => setChangeTransactionPasswordModal(true) } 
                        style={{ backgroundImage: `url(${assets.PASSWORD_BTN_BG})` }}
                    >  
                        <span>{t("Change Transaction Password")}</span>
                    </button> 
                </div>
            </ModalBox> 

            {/*setChangePasswordModal */}
            <ModalBox
                isOpen={changePasswordModal} 
                handleClose={() => setChangePasswordModal(false) }
                title={t("Change password")}
                className={"update_password_form"}
            >
                <div className="transfer-form"> 
                    <form className="form" onSubmit={handleChangePasswordFormSubmit} method="POST">   
                        <div className="transfer-content"> 
                            <div className="form-group">
                                <label>{t("Current Login Password")}</label>
                                <div className="input" style={{ backgroundImage: `url(${assets.PASSWORD_INPUT_BG})` }}> 
                                    <input 
                                        ref={inputRef} 
                                        className="input-password" 
                                        type="password"  
                                        onChange={(e)=>inputChangeHandler(setCurrentLoginPassword, e)} 
                                    /> 
                                </div>
                            </div>
                            <div className="form-group">
                                <label>{t("New Login Password")}</label>
                                <div className="input" style={{ backgroundImage: `url(${assets.PASSWORD_INPUT_BG})` }}> 
                                    <input 
                                        ref={inputRef} 
                                        className="input-password" 
                                        type="password"  
                                        onChange={(e)=>inputChangeHandler(setNewLoginPassword, e)} 
                                    /> 
                                </div>
                            </div>
                            <div className="form-group">
                                <label>{t("Confirm Login Password")}</label>
                                <div className="input" style={{ backgroundImage: `url(${assets.PASSWORD_INPUT_BG})` }}> 
                                    <input 
                                        ref={inputRef} 
                                        className="input-password" 
                                        type="password"  
                                        onChange={(e)=>inputChangeHandler(setConfirmLoginPassword, e)} 
                                    /> 
                                </div>
                            </div>
                        </div>
                        <div className="transfer-footer"> 
                            <button 
                                className="btnSubmit"  
                                disabled={!canSubmitChangePassword} 
                                type="submit"
                            > 
                                <img src={assets.SUBMIT_BTN} width={100} alt="submit" />
                                <span>{t("Confirm")}</span>
                            </button>
                        </div>
                    </form>
                </div>
            </ModalBox> 

            {/*setChangeTransactionPasswordModal */}
            <ModalBox
                isOpen={changeTransactionPasswordModal} 
                handleClose={() => setChangeTransactionPasswordModal(false) }
                title={t("Update transaction password")}
                className={"update_password_form"}
            >
                <div className="transfer-form">
                    <form className="form" onSubmit={handleChangeTransactionPasswordFormSubmit} method="POST" autoComplete='off'>   
                        <div className="transfer-content"> 
                            <div className="form-group">
                                <label>{t("OTP")}</label>
                                <InputGroup className="input-group-alternative"  style={{ backgroundImage: `url('${assets.PASSWORD_INPUT_BG}')` }}>  
                                    <Input 
                                        ref={inputRef} 
                                        name="Transaction_OTP"
                                        placeholder={t("Enter OTP")}  
                                        onChange={(e)=>inputChangeHandler(setTransactionPasswordOtp, e)}    
                                        required
                                    />
                                    <span className="btn btn-request"> 
                                        <button type="button" className="btnSubmit" disabled={!canSubmitOtp} onClick={sendOtpForm}>
                                            <img src={assets.SUBMIT_BTN} width={100} />
                                            <span>{t("Request")}</span>
                                            <span className="btn-remark"> {countdown > 0 ? `(${countdown} seconds)` : ''}</span>
                                        </button> 
                                    </span> 
                                </InputGroup>
                            </div>  
                            <div className="form-group">
                                <label>{t("New Transaction Password")}</label>
                                <div className="input" style={{ backgroundImage: `url(${assets.PASSWORD_INPUT_BG})` }}> 
                                    <input 
                                        ref={inputRef} 
                                        className="input-password" 
                                        type="password"   
                                        onChange={(e)=>inputChangeHandler(setNewTansactionPassword, e)} 
                                    /> 
                                </div>
                            </div>
                            <div className="form-group">
                                <label>{t("Confirm Transaction Password")}</label>
                                <div className="input" style={{ backgroundImage: `url(${assets.PASSWORD_INPUT_BG})` }}> 
                                    <input 
                                        ref={inputRef} 
                                        className="input-password" 
                                        type="password" 
                                        onChange={(e)=>inputChangeHandler(setConfirmTransactionPassword, e)} 
                                    /> 
                                </div>
                            </div>
                        </div>
                        <div className="transfer-footer"> 
                            <button 
                                className="btnSubmit"  
                                disabled={!canSubmitChangeTrasactionPassword} 
                                type="submit"
                            > 
                                <img src={assets.SUBMIT_BTN} width={100} alt="submit" />
                                <span>{t("Confirm")}</span>
                            </button>
                        </div>
                    </form>
                </div>
            </ModalBox> 
 
            {/*setWithdrawalModal */}
            
            <WithdrawalFormModal
                isOpen={withdrawalModal}
                handleClose={() => setWithdrawalModal(false)}
                title="Withdrawal"
                onSubmit={confirmWithdrawalFormSubmit}
                onChange={(e) => inputChangeHandler(setWithdrawalAmount, e)}
                inputRef={inputRef}
                minValue={wMinAmount}
                maxValue={wMaxAmount}
                error={amountError}
                canSubmit={canConfirmWithdrawal} 
                icon={assets.USDT_BTN}
                inputBg={assets.PASSWORD_INPUT_BG}
                total_usdt={total_usdt}
                sendOtpForm={sendOtpForm}
                countdown={countdown}
                withdrawalFee={withdrawalFee}
            >
            </WithdrawalFormModal>
  
            {/*setWithdrawalConfirmModal */}
            <ModalBox
                isOpen={withdrawalConfirmModal}  
                handleClose={() => {
                    setWithdrawalConfirmModal(false);
                    setWithdrawalModal(true);
                }} 
                title={t("Withdrawal")}
            > 
                <div className="modal-notif-content"> 
                    <h4>{t("Withdrawal Balance")}</h4>
                    <div className="withdrawal-detail">
                        <div className="withdrawal-detail-row">
                            <span className="row-title">{t("Withdrawal Amount")}</span>
                            <span className="row-value">
                                {withdrawalAmount}
                                 <img className="bar-coin-icon" src={assets.USDT_BTN} height={22}/>
                            </span>
                        </div>
                        <div className="withdrawal-detail-row">
                            <span className="row-title">{t("Processing Fee")}</span>
                            <span className="row-value">
                                -{withdrawalFee}
                                <img className="bar-coin-icon" src={assets.USDT_BTN} height={22}/>
                            </span>
                        </div>
                        <div className="withdrawal-detail-row withdrawal-total">
                            <span className="row-title">{t("Total")}</span>
                            <span className="row-value">
                                {withdrawalFinal}
                                <img className="bar-coin-icon" src={assets.USDT_BTN} height={22}/>
                            </span>
                        </div>

                        <button className="btnSubmit mt-4" onClick={() => handleConfirmWithdrawalButton()}>
                            <img src={assets.SUBMIT_BTN} width={100} />
                            <span>{t("Confirm")}</span>
                        </button>
                    </div>
                </div> 
            </ModalBox> 

            {/*transaction password modal */}
            <ModalBox
                isOpen={passwordModal} 
                handleClose={() => setPasswordModal(false) }
                title={t("Enter transaction password")}
                className={"transaction_password_form"}
            >
                <div className="transfer-form">
                    <div className="transfer-header"> 
                        <img className="btn-transfer-bg" src={assets.PASSWORD_ICON} height={60}/>
                    </div>
                    <div className="transfer-content">
                        <div className="form-group">
                            <label>{t("Transaction Password")}</label>
                            <div className="input" style={{ backgroundImage: `url(${assets.PASSWORD_INPUT_BG})` }}> 
                                <input 
                                    ref={inputRef} 
                                    className="input-password" 
                                    type="password"  
                                    onChange={(e)=>inputChangeHandler(setTransactionPassword, e)}  />
                            </div>
                        </div>
                    </div>
                    <div className="transfer-footer"> 
                        <button 
                            className="btnSubmit" 
                            onClick={ () => confirmPassword()} 
                            disabled={!canSubmitPassword} 
                        > 
                            <img src={assets.SUBMIT_BTN} width={100} alt="submit" />
                            <span>{t("OK")}</span>
                        </button>
                    </div>
                </div>
            </ModalBox> 
 
            <Footer/>
        </div> 
    </>
    );
};