import React, {useEffect,useState,useRef } from 'react';

import { useNavigate, useLocation  } from "react-router-dom"; 
import { useTranslation } from 'react-i18next'; 
import useLocalStorage from './useLocalStorage';
import  { createApiCall }  from './createApiCall'
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import ModalService from './ModalService';
import ModalBox from './ModalBox' 
import { Progress } from 'react-vant';
import { parseMessage, maskEmail, setLocalStorageItems, getValue, updateWalletLocalStorage, uuBalance } from '../../Services/Utils'  

import WalletModal from './WalletModal' 
import RedeemModal from './RedeemModal' 
import PurchaseModal from './PurchaseModal';
import WithdrawalFormModal from './WithdrawalFormModal';

import { 
    Button, 
    FormGroup, 
    Input, 
    InputGroupText,
    InputGroup, 
} from "reactstrap"; 

import assets from '../../assets.json';

export default function DashboardHeader(props) {
    const inputRef  = useRef(null);
    const navigate  = useNavigate();
    const { t }     = useTranslation();
    const { i18n } = useTranslation(); 
    const location  = useLocation();
    const currentPath = location.pathname;
    const path = currentPath.substring(1);
 
    const [usdtModal, setUsdtModal] = useState(false);   
    const [minerModal, setMinerModal] = useState(false);   
    const [bonusModal, setBonusModal] = useState(false);    
    const [withdrawalModal, setWithdrawalModal] = useState(false);   
    const [withdrawalConfirmModal, setWithdrawalConfirmModal] = useState(false);  
    const [passwordModal, setPasswordModal] = useState(false);
    const [otpType, setOtpType] = useState(null);   
    const [canSubmitPassword, setCanSubmitPassword] = useState(false);  
 
    const [time] = useState(10);
    const [canSubmitOtp, setCanSubmitOtp] = useState(false);
    const [sendOtp, setSendOtp] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [withdrawalAmount, setWithdrawalAmount] = useState(0);
    const [withdrawalFee, setWithdrawalFee] = useState(0);
    const [withdrawalFinal, setWithdrawalFinal] = useState(0);

    const [redeemProgress, setRedeemProgress] = useState(0);
    const [canSubmitRedeem, setCanSubmitRedeem] = useState(false);
    const [transactionPassword, setTransactionPassword] = useState("");
 
    const [amountError, setAmountError] = useState("");
    const [wMinAmount, setWMinAmount] = useState(0);
    const [wMaxAmount, setWMaxAmount] = useState(0);
    const [walletAddress, setWalletAddress] = useState(null);
    const [canConfirmWithdrawal, setCanConfirmWithdrawal] = useState(false);
    const [transactionPasswordFormType, setTransactionPasswordFormType] = useState(null);
    const [minerQty, setMinerQty] = useState(0);
    const [minerError, setMinerError] = useState(null);
    const [canConfirmMiner, setCanConfirmMiner] = useState(false);
    const [transactionPasswordOtp, setTransactionPasswordOtp] = useState(null);  
    const [redeemInfo, setRedeemInfo ] = useState([]);
   
    const [email] = useLocalStorage('username', "test@test.com");
    const [total_bonus, setTotal_bonus] = useState(localStorage.getItem('bonus') || 0);
    const [total_usdt, setTotal_usdt] = useState(localStorage.getItem('usdt') || 0);
    const [total_miner, setTotal_miner] = useState(localStorage.getItem('miner') || 0);
         
    const  updateBalance = () => { 
        const updatedValues = uuBalance();
        
        if (updatedValues) {
            if (updatedValues.bonus) {
                setTotal_bonus(updatedValues.bonus);
            }
            if (updatedValues.usdt) {
                setTotal_usdt(updatedValues.usdt);
            }
            if (updatedValues.miner) {
                setTotal_miner(updatedValues.miner);
            }
        }
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            updateBalance()
        }, 1000); // 10 seconds
    
        return () => {
          clearInterval(intervalId);
        };
    }, []);
     


    const storedSettings = JSON.parse(localStorage.getItem('gameSetting'));  
    const memberDetails = JSON.parse(localStorage.getItem('memberDetails'));

    const getSettingByKey = (key) => {
        if(storedSettings){ 
            const setting = storedSettings.find(item => item.key === key);
            return setting ? setting.value : null; 
         }
         return null;
     };

    const getMemberByKey = (key) => {
        return memberDetails && memberDetails[key] ? memberDetails[key] : null;
    };

    let trc20_wallet_address = getMemberByKey("trc20_wallet_address") || null;  
    let usdt_wallet_address = getMemberByKey("usdt_wallet_address") || null; 
  
    const inputChangeHandler = (setFunction, event) => {  
        setFunction(event.target.value) 
    }     
    
    const copyToClipboard  = (email, type) => {  
        const textArea = document.createElement('textarea');
        textArea.value = email; 
        document.body.appendChild(textArea); 
        textArea.select();
        document.execCommand('copy'); 
        document.body.removeChild(textArea); 
        Notify.success(parseMessage(t("%type have copy to clipboard"),{"%type": t(type)}));   
    }; 

    useEffect(() => {  
        loadSetting() 
    }, []); 
   
    useEffect(() => {   
        let progress = parseFloat(total_bonus)/parseFloat(redeemInfo.value)*100;
        if(progress > 100){
            progress = 100;
        }
        setRedeemProgress(progress); 
        
        if(redeemInfo.redeem_count < redeemInfo.maximum_redeem && total_bonus >= redeemInfo.value){ 
            setCanSubmitRedeem(true);
        } else {
            setCanSubmitRedeem(false);
        }

    }, [redeemInfo]); 
  
    useEffect(() => { 
        if(transactionPassword){
            setCanSubmitPassword(true);
        }else{
            setCanSubmitPassword(false);
        } 
    }, [transactionPassword]); 
     
    useEffect(() => {
        if (sendOtp) {
            const timer = setTimeout(() => {
            setSendOtp(false);
            }, ((time+1) * 1000)); // 15 seconds

            return () => {
                clearTimeout(timer);
            };
        }
    }, [sendOtp]);

    useEffect(() => {
        if (sendOtp) {
            const interval = setInterval(() => {
                setCountdown(countdown - 1);
            }, 1000); // 1 second 
            return () => {
                clearInterval(interval);
            };
        }
    }, [sendOtp, countdown]);
         
    useEffect(() => { 
        if(sendOtp === false){
            setCanSubmitOtp(true);
        }else{
            setCanSubmitOtp(false);
        } 
    }, [ sendOtp]); 
         
    useEffect(() => { 
        if( withdrawalModal ){ 
            setOtpType("withdrawal")
        }
        else{
            setOtpType(null)
        } 
    }, [ withdrawalModal ]); 
  
    useEffect(() => { 
       let withdrawalMinAmount = getSettingByKey("usdt_withdrawal_min") || 0; 
       let withdrawalMaxAmount = getSettingByKey("usdt_withdrawal_max") || 0;
       let usdt_withdrawal_charges = getSettingByKey("usdt_withdrawal_charges") || 0;
       setWMinAmount(withdrawalMinAmount);
       setWMaxAmount(withdrawalMaxAmount);
       setWithdrawalFee(usdt_withdrawal_charges);

        let withdrawalAmount2 = parseFloat(withdrawalAmount);
        let withdrawalMinAmount2 = parseFloat(withdrawalMinAmount);
        let withdrwalMaxAmount2 = parseFloat(withdrawalMaxAmount);
        if(withdrawalAmount2 < withdrawalMinAmount2){
            setCanConfirmWithdrawal(false);
            setAmountError(parseMessage(t("min withdrawal amount: %amount USDT"), {"%amount": withdrawalMinAmount2}));
        }
        else if(withdrawalAmount2 > withdrwalMaxAmount2){
            setCanConfirmWithdrawal(false);
            setAmountError(parseMessage(t("max withdrawal amount: %amount USDT"), {"%amount": withdrwalMaxAmount2 }));
        } 
        else{
            setAmountError("");
            if(transactionPasswordOtp && walletAddress){
                setCanConfirmWithdrawal(true);
            } else {
                setCanConfirmWithdrawal(false);
            }
        } 


       setWithdrawalFinal(withdrawalAmount - withdrawalFee);
        
    }, [withdrawalAmount, transactionPasswordOtp, walletAddress]); 
 
    useEffect(() => {  
        if(minerQty <= 0){
            setCanConfirmMiner(false);
            setMinerError(t("Min miner to purchase: 1"));
        } 
        else{
            let requestUsdt = minerQty * 1;
            setMinerError("");
            if( parseFloat(requestUsdt) > parseFloat(total_usdt) ){
                setMinerError(t("Balance not enough"));
                setCanConfirmMiner(false);
            } else {
                setCanConfirmMiner(true);
                setMinerError("");
            }
        }   
    }, [minerQty]); 
  
    const gameSetting =  async () => {
        const apiCall = createApiCall("game/setting", {}, (response) => {
            if(response.result === 1){    
                let gameSetting = response.setting;
                localStorage.setItem('gameSetting', JSON.stringify(gameSetting));  
            } else {   
                ModalService.showModal(<div>{t(response.message_code)}</div>,{type: "error"}); 
            }
        })
        await apiCall(navigate, t);
    };   

    const loadSetting =  async () => {
        const apiCall = createApiCall("login", {}, (response) => { 
            let member_details= response.data.member_details 
            setLocalStorageItems(member_details);
            
            const updatedValues = updateBalance();
            if (updatedValues) {
                if (updatedValues.bonus) {
                    setTotal_bonus(updatedValues.bonus);
                }
                if (updatedValues.usdt) {
                    setTotal_usdt(updatedValues.usdt);
                }
                if (updatedValues.miner) {
                    setTotal_miner(updatedValues.miner);
                }
            }
            let newlang = member_details.language; 
            i18n.changeLanguage(newlang); 

            gameSetting();   
            fetchRedeemInfo()  
        })
        await apiCall(navigate, t);
    };
    
    const fetchRedeemInfo =  async () => {
        const apiCall = createApiCall("package/getRedeemInfo", {}, (response) => { 
            let data = response.redeemInfo;
            if (data.length > 0) {
              setRedeemInfo(data[0]);
            } 
        })
        await apiCall(navigate, t);
    }; 
   
    const sendOtpForm = async () => { 
        const apiCall = createApiCall("otp/request", {
            type: otpType, 
            email: email
        }, (response) => {
            if(response.result === 1){     
                setSendOtp(true) 
                setCountdown(time);
                Notify.success(t("OTP have send to your email"));   
            }
        });
        await apiCall(navigate, t);
    };
 
    const confirmWithdrawalFormSubmit = async (e) => { 
        e.preventDefault(); 
        if(parseFloat(withdrawalAmount) > parseFloat(total_usdt))
        { 
            Notify.failure(t("Not enought USDT to withdraw")); 
            return false;
        }
        setWithdrawalConfirmModal(true);
        setWithdrawalModal(false);
  
    };

    const handleConfirmWithdrawalButton = () => {
        setTransactionPasswordFormType("withdrawal");
        setPasswordModal(true);
    }

    const confirmPassword = () => {

        if(transactionPasswordFormType === "withdrawal"){
            handleConfirmWithdrawalFormSubmit()
        } else if(transactionPasswordFormType === "miner"){
            handleConfirmMinerFormSubmit()
        }
    }
 
    const handleConfirmWithdrawalFormSubmit = async () => {

        const apiCall = createApiCall("member/withdrawUsdt", {
            type: "trc20", 
            amount: withdrawalAmount,
            wallet_address: walletAddress,
            amount: withdrawalAmount,
            transaction_password: transactionPassword,
            otp: transactionPasswordOtp,
        }, (response) => {
            if(response.result === 1){    
                ModalService.showModal(<div>{t("Withdrawal Successful!!")}</div>,{type: "happy"}); 
                setTransactionPasswordOtp(null); 
                setWalletAddress(null); 
                setWithdrawalAmount(null); 
                setTransactionPassword(false); 
                setWithdrawalConfirmModal(false); 
                setPasswordModal(false); 

                updateWalletLocalStorage(response.wallet);
                 
                const updatedValues = updateBalance();
                if (updatedValues) {
                    if (updatedValues.bonus) {
                        setTotal_bonus(updatedValues.bonus);
                    }
                    if (updatedValues.usdt) {
                        setTotal_usdt(updatedValues.usdt);
                    }
                    if (updatedValues.miner) {
                        setTotal_miner(updatedValues.miner);
                    }
                }

                inputRef.current.value = '';
            } else {  
                if(response.message_code === "401"){
                    ModalService.showModal(<div>{t("Wrong transaction password")}</div>,{type: "error"}); 
                } else {
                    ModalService.showModal(<div>{t(response.message_code)}</div>,{type: "error"}); 
                }
            }
        });
        await apiCall(navigate, t);
    };

    const handleConfirmMinerFormSubmit = async () => {

        const apiCall = createApiCall("wallet/topUpMiner", {
            amount: minerQty, 
            transaction_password: transactionPassword,
        }, (response) => { 
            if(response.result === 1){    
                ModalService.showModal(<div>{t("Purchase Successful!!")}</div>,{type: "happy"}); 
                let member_details= response.member 
                setLocalStorageItems(member_details);
                setPasswordModal(false);
                setMinerModal(false);
                inputRef.current.value = '';
            } else { 
                ModalService.showModal(<div>{t(response.message_code)}</div>,{type: "error"}); 
            }
        });
        await apiCall(navigate, t);
    };
 
    const handleWithdrawBtn = () => {
        setWithdrawalModal(true) 
        setUsdtModal(false) 
    }
     
    const confirmMinerForm = (e) => { 
        e.preventDefault();
        setTransactionPasswordFormType("miner");
        setPasswordModal(true);
    }
       
    const confirmRedeemMine =  async (package_id) => {
        const apiCall = createApiCall("wallet/redeemMine", {
            package_id: package_id
        }, (response) => {
            if(response.result === 1){    
                ModalService.showModal(<div>{t("Redeem Successful!!")}</div>,{type: "excited"}); 
                        
                loadSetting() 
                fetchRedeemInfo()  
            } else {   
                ModalService.showModal(<div>{t(response.message_code)}</div>,{type: "error"}); 
            }
        })
        await apiCall(navigate, t);
    };
     
    return (
        <div className="dashboard-header"
            style={{ backgroundImage: "url('assets/bottom_bar.png')" }}
            >   
            <div className="dashboard-header-bar"> 
                <span className="bar-coin btn-usdt" onClick={ () => setUsdtModal(true) } > 
                    <img className="bar-coin-icon" src={`assets/coin_USDT.png`} height={45}/>
                    <img className="bar-coin-bg" src={`assets/bar_coin_amount.png`} height={40} />
                    <span className="bar-coin-input">{total_usdt}</span>
                </span>
                <span className="bar-coin btn-miner" onClick={ () => setMinerModal(true) } > 
                    <img className="bar-coin-icon" src={`assets/coin_worker.png`} height={45}/>
                    <img className="bar-coin-bg" src={`assets/bar_coin_amount.png`} height={40} />
                    <span className="bar-coin-input">{total_miner}</span>
                </span>
                <span className="bar-coin btn-bonus" onClick={ () => setBonusModal(true) } > 
                    <img className="bar-coin-icon" src={`assets/coin_Bonus.png`} height={45}/>
                    <img className="bar-coin-bg" src={`assets/bar_coin_amount.png`} height={40} />
                    <span className="bar-coin-input">{total_bonus}</span>
                </span>
            </div> 
 
            {/*setBonusModal */} 
            <RedeemModal
                isOpen={bonusModal}
                handleClose={() => setBonusModal(false)}
                title="Redeem Bonus"
                redeemInfo={redeemInfo}
                redeemProgress={redeemProgress}
                totalBonus={total_bonus}
                canSubmitRedeem={canSubmitRedeem}
                onRedeemClick={() => confirmRedeemMine(redeemInfo.package_id)}
                silverStarterIcon={assets.SILVERSTARTER}
                goldStarterIcon={assets.GOLDSTARTER} 
            > 
            </RedeemModal>
  
            {/*setMinerModal */} 
            <PurchaseModal
                isOpen={minerModal}
                handleClose={() => setMinerModal(false)}
                title="Purchase Miner"
                onSubmit={confirmMinerForm}
                onChange={(e) => inputChangeHandler(setMinerQty, e)}
                inputRef={inputRef}
                minValue={1}
                maxValue={100} // adjust the max value as needed
                error={minerError}
                canSubmit={canConfirmMiner} 
                icon={assets.MINER_BTN}
                inputBg={assets.PASSWORD_INPUT_BG}
            > 
            </PurchaseModal> 
   
            {/*setUsdtModal */} 
            <WalletModal
                isOpen={usdtModal}
                handleClose={() => setUsdtModal(false)}
                title="USDT Balance" 
                walletAddress={trc20_wallet_address}
                disabled={true}
                onCopyClick={() => copyToClipboard(trc20_wallet_address, "TRC 20 Wallet address")}
                onWithdrawClick={handleWithdrawBtn}
                > 
            </WalletModal>
 
            {/*setWithdrawalModal */} 
            <WithdrawalFormModal
                isOpen={withdrawalModal}
                handleClose={() => setWithdrawalModal(false)}
                title="Withdrawal"
                onSubmit={confirmWithdrawalFormSubmit}
                onChange={(e) => inputChangeHandler(setWithdrawalAmount, e)}
                inputRef={inputRef}
                minValue={wMinAmount}
                maxValue={wMaxAmount}
                error={amountError}
                canSubmit={canConfirmWithdrawal} 
                icon={assets.USDT_BTN}
                inputBg={assets.PASSWORD_INPUT_BG}
                total_usdt={total_usdt}
                sendOtpForm={sendOtpForm}
                countdown={countdown}
                withdrawalFee={withdrawalFee}
            >
            </WithdrawalFormModal>

  
            {/*setWithdrawalConfirmModal */}
            <ModalBox
                isOpen={withdrawalConfirmModal}  
                handleClose={() => {
                    setWithdrawalConfirmModal(false);
                    setWithdrawalModal(true);
                }} 
                title={t("Withdrawal")}
            > 
                <div className="modal-notif-content"> 
                    <h4>{t("Withdrawal Balance")}</h4>
                    <div className="withdrawal-detail">
                        <div className="withdrawal-detail-row">
                            <span className="row-title">{t("Withdrawal Amount")}</span>
                            <span className="row-value">
                                {withdrawalAmount}
                                 <img className="bar-coin-icon" src={`assets/coin_USDT.png`} height={22}/>
                            </span>
                        </div>
                        <div className="withdrawal-detail-row">
                            <span className="row-title">{t("Processing Fee")}</span>
                            <span className="row-value">
                                -{withdrawalFee}
                                <img className="bar-coin-icon" src={`assets/coin_USDT.png`} height={22}/>
                            </span>
                        </div>
                        <div className="withdrawal-detail-row withdrawal-total">
                            <span className="row-title">{t("Total")}</span>
                            <span className="row-value">
                                {withdrawalFinal}
                                <img className="bar-coin-icon" src={`assets/coin_USDT.png`} height={22}/>
                            </span>
                        </div>

                        <button className="btnSubmit mt-4" onClick={() => handleConfirmWithdrawalButton()}>
                            <img src={assets.SUBMIT_BTN} width={100} />
                            <span>{t("Confirm")}</span>
                        </button>
                    </div>
                </div> 
            </ModalBox> 

            {/*transaction password modal */}
            <ModalBox
                isOpen={passwordModal} 
                handleClose={() => setPasswordModal(false) }
                title={t("Enter transaction password")}
                className={"transaction_password_form"}
            >
                <div className="transfer-form">
                    <div className="transfer-header"> 
                        <img className="btn-transfer-bg" src={assets.PASSWORD_ICON} height={60}/>
                    </div>
                    <div className="transfer-content">
                        <div className="form-group">
                            <label>{t("Transaction Password")}</label>
                            <div className="input" style={{ backgroundImage: `url(${assets.PASSWORD_INPUT_BG})` }}> 
                                <input 
                                    ref={inputRef} 
                                    className="input-password" 
                                    type="password"  
                                    onChange={(e)=>inputChangeHandler(setTransactionPassword, e)}  />
                            </div>
                        </div>
                    </div>
                    <div className="transfer-footer"> 
                        <button 
                            className="btnSubmit" 
                            onClick={ () => confirmPassword()} 
                            disabled={!canSubmitPassword} 
                        > 
                            <img src={assets.SUBMIT_BTN} width={100} alt="submit" />
                            <span>{t("OK")}</span>
                        </button>
                    </div>
                </div>
            </ModalBox> 


        </div> 
      );
} 