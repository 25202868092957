import { createContext, useState, useEffect } from 'react';

const AudioContext = createContext();

const AudioProvider = ({ children }) => {
  const [audio, setAudio] = useState(null);

  useEffect(() => {
    const audioElement = new Audio('audio/bg.mp3');
    audioElement.loop = true;
    setAudio(audioElement);
  }, []);

  return (
    <AudioContext.Provider value={{ audio }}>
      {children}
    </AudioContext.Provider>
  );
};

export { AudioProvider, AudioContext };