import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next'; 

const Countdown = ({ initialSeconds, className }) => {

  const { t } = useTranslation();
  const [seconds, setSeconds] = useState(initialSeconds);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    if (seconds > 0) {
      const intervalId = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
      setIntervalId(intervalId);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [seconds]);

  const formatCountdown = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secondsRemaining = seconds % 60;
  
    let countdownString = '';
    if (hours > 0) {
      countdownString += `${hours}${t("H")} `;
    }
    if (minutes > 0) {
      countdownString += `${minutes}${t("m")} `;
    }
    countdownString += `${secondsRemaining}${t("s")} `;
  
    return countdownString.trim();
  };

  return (
    <span className={className}>
      {seconds > 0 ? formatCountdown(seconds) : t("Time's up!")}
    </span>
  );
};

export default Countdown;