import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ModalBox from './ModalBox' 

import assets from '../../assets.json';

const PurchaseModal = ({
  isOpen,
  handleClose,
  title,
  onSubmit,
  onChange,
  inputRef,
  minValue,
  maxValue,
  error,
  canSubmit, 
  icon,
  inputBg,
  children,
}) => {
  const { t } = useTranslation();
  const inputElement = useRef(null);

  return (
    <ModalBox
      isOpen={isOpen}
      handleClose={handleClose}
      title={t(title)}
    >
      <div className="transfer-form withdrawal-form">
        <form className="form" onSubmit={onSubmit} method="POST" autoComplete="off">
          <div className="transfer-content">
            <div className="text-center mt-4">
              <img className="bar-coin-icon" src={icon} height={50} />
            </div>
            <div className="form-group">
              <label>{t("Miner")}</label>
              <div className="input" style={{ backgroundImage: `url(${inputBg})` }}>
                <input
                  ref={inputRef}
                  min={minValue}
                  max={maxValue}
                  type="number"
                  onChange={onChange}
                  required
                />
                <img className="input-transfer-icon" src={icon} height={20} />
              </div>
              {error && (
                <div className="errorMessage">{error}</div>
              )}
            </div>
          </div>
          <div className="transfer-footer">
            <button className="btnSubmit" disabled={!canSubmit}>
              <img src={assets.SUBMIT_BTN} width={100} />
              <span>{t("confirm")}</span>
            </button>
          </div>
        </form>
        {children}
      </div>
    </ModalBox>
  );
};

export default PurchaseModal;