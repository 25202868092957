import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ModalBox from './ModalBox' 

import { 
    Button, 
    FormGroup, 
    Input, 
    InputGroupText,
    InputGroup, 
} from "reactstrap"; 

import assets from '../../assets.json';

const WithdrawalFormModal = ({
    isOpen,
    handleClose,
    title,
    onSubmit,
    onChange,
    inputRef,
    minValue,
    maxValue,
    error,
    canSubmit, 
    icon,
    inputBg,
    total_usdt,
    sendOtpForm,
    countdown,
    withdrawalFee,
    children,
}) => {
  const { t } = useTranslation();
  const inputElement = useRef(null);
 
  return (
    <ModalBox
      isOpen={isOpen}
      handleClose={handleClose}
      title={t(title)}
    >
      <div className="transfer-form withdrawal-form">
        <form className="form" onSubmit={onSubmit} method="POST" autoComplete="off">
          <div className="transfer-content">
            <div className="form-group">
              <span className="bar-coin btn-usdt">
                <img className="bar-coin-icon" src={`assets/coin_USDT.png`} height={35} />
                <img className="bar-coin-bg" src={`assets/bar_coin_amount.png`} height={30} />
                <span className="bar-coin-input">{total_usdt}</span>
              </span>
            </div>
            <div className="form-group">
              <label>{t("Withdrawal Amount")}</label>
              <div className="input" style={{ backgroundImage: `url(${inputBg})` }}>
                <input
                  ref={inputRef}
                  max={maxValue}
                  min={minValue}
                  type="number"
                  onChange={onChange}
                  required
                />
                <img className="input-transfer-icon" src={icon} height={20} />
              </div>
              {error && (
                <div className="errorMessage">{error}</div>
              )}
            </div>
            <div className="form-group">
              <label>{t("Wallet Address")}</label>
              <div className="input" style={{ backgroundImage: `url(${inputBg})` }}>
                <input
                  ref={inputRef}
                  className="input-password"
                  onChange={onChange}
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label>{t("OTP")}</label>
              <InputGroup className="input-group-alternative" style={{ backgroundImage: "url('assets/bar_fill_text_01.png')" }}>
                <Input
                  ref={inputRef}
                  name="Transaction_OTP"
                  placeholder={t("Enter OTP")}
                  onChange={onChange}
                  required
                />
                <span className="btn btn-request">
                  <button type="button" className="btnSubmit" disabled={!canSubmit} onClick={sendOtpForm}>
                    <img src={assets.SUBMIT_BTN} width={100} />
                    <span>{t("Request")}</span>
                    <span className="btn-remark"> {countdown > 0 ? `(${countdown} seconds)` : ''}</span>
                  </button>
                </span>
              </InputGroup>
            </div>
            <div className="form-group">
              <div className="chain-info">
                <label>{t("Chain Name")}</label>
                <div className="chainName">{t("TRC20")}</div>
                <div>{t("Withdrawal time will be T+1.")}</div>
                <div>
                  {t("Every Withdrawal Will Charged")}+{withdrawalFee}
                  <img className="bar-coin-icon" src={`assets/coin_USDT.png`} height={15} />
                  {t("Processing Fee")}
                </div>
              </div>
            </div>
          </div>
          <div className="transfer-footer">
            <button className="btnSubmit" disabled={!canSubmit}>
              <img src={assets.SUBMIT_BTN} width={100} />
              <span>{t("withdraw")}</span>
            </button>
          </div>
        </form>
        {children}
      </div>
    </ModalBox>
  );
};

export default WithdrawalFormModal;