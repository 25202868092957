import React from 'react';
import ReactDOM from 'react-dom/client';
import Modal from './Modal';

class ModalService {
  constructor() {
    this.modalRoot = document.createElement('div');
    this.modalRoot.id = 'modal-root';
    document.body.appendChild(this.modalRoot);
    this.root = ReactDOM.createRoot(this.modalRoot);
  }

  showModal(content, additionalParams) {
    this.root.render(
      <Modal onClose={() => {
        if (typeof additionalParams === 'function') {
          additionalParams();
        }
        this.hideModal();
      }} {...additionalParams}>{content}</Modal>
    );
  }

  hideModal() {
    this.root.render(null); 
  }
}

const modalService = new ModalService();
export default modalService;
