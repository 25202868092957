// AppRouter.js
import React from 'react';
import { HashRouter, Route, Routes, Switch, Navigate } from 'react-router-dom';
  
import Home from '../Page/Dashboard'; 
import Activity from '../Page/Activity'; 
import Alliance from '../Page/Alliance'; 
import Profile from '../Page/Profile'; 
 
import Login from '../Page/Login';

import PageNotFound from '../Layout/PageNotFound';   


const AppRouter = () => { 
  if (window.location.hash !== '') {
    window.location.href = '/';
  }
 
  return (
    <HashRouter> 
      <Routes>  
        <Route  path="*" element={<Navigate to="404" />} />
        <Route  path="/*" element={<PageNotFound />} />


        <Route  path="/home" element={<Home/>}  />
        <Route  path="/activity" element={<Activity/>}  />
        <Route  path="/alliance" element={<Alliance/>}  />
        <Route  path="/profile" element={<Profile/>}  />
        <Route  path="/login" element={<Login/>}  />
        
        <Route  exact path="/" element={<Home/>}  />   
      </Routes>
    </HashRouter>
  );
};

export default AppRouter;