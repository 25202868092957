// src/GoldRock.js
import React from 'react';
import '../../styles/animation.scss'; // Import CSS file from src/styles

const GoldRock = () => {
  return (
    <>  
    <div className="mine_blink blink1" />  
    <div className="mine_blink blink2" />   
    <div className="mine_grow grow1" />   
    <div className="mine_grow grow2" />   
    <div className="mine_grow grow3" />   
    </>
  );
};

export default GoldRock;
